import React, {useRef, useState} from 'react';
import {Button, Col, Divider, Form, FormInstance, Input, Modal, Row} from "antd";
import ReactDOMServer from 'react-dom/server';
import {useDispatch, useSelector} from "react-redux";
import {EditOutlined} from "@ant-design/icons";
import EditableTextService from "../service/app/EditableTextService";
import {updateSetup} from "../redux/slice/Setup";
import {IAppState} from "../interface/redux/IAppState";
import {AppDispatch} from "../redux/store";
import Wysiwyg from "../util/Wysiwyg";

type TextType = 'wysiwyg' | 'plain' | 'text' | 'email'

interface IProps {
    code: string
    children?: React.ReactNode | string | React.JSX.Element
    type?: TextType
    wrap?: boolean
}

export const LANGUAGE = 'cz'

const EditableText: React.FC<IProps> = ({children, type = 'plain', code, wrap = false}) => {


    const formRef = useRef<FormInstance>()
    const [saving, setSaving] = useState(false)
    const [editing, setEditing] = useState(false)

    const dispatch = useDispatch<AppDispatch>();
    const texts = useSelector((state: IAppState) => state.setup.editableTexts);
    const user = useSelector((state: IAppState) => state.setup.user);

    const editableText = texts.find(t => t.id === code)

    const text = editableText?.versions.find(v => v.language === LANGUAGE)?.text || ''

    const submit = async () => {
        formRef.current?.validateFields().then(async (values: {text: string}) => {
            setSaving(true)
            const result = await EditableTextService.create({
               id: code,
                versions: [
                    {language: LANGUAGE, text: values.text}
                ]
            })
            setSaving(false)
            setEditing(false)
            let updatedTexts = [...texts]
            const index = updatedTexts.findIndex(h => h.id === result.id)
            if (index > -1){
                updatedTexts[index] = result
            } else {
                updatedTexts.push(result)
            }
            dispatch(updateSetup({editableTexts: updatedTexts}));
        })
    }

    function getEditButton() {
        return user && <div className="position-absolute" style={{top: '-25px', right: '-32px', zIndex: 10}}>
            <Button icon={<EditOutlined/>} shape={'circle'} onClick={() => setEditing(true)}/>
        </div>;
    }

    function isHTMLString(str: string) { const parser = new DOMParser(); const doc = parser.parseFromString(str, 'text/html'); return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); }


    function decodeHTML(text: string) {
        const entityMap: {[prop: string]: string} = { '&amp;': '&', '&lt;': '<', '&gt;': '>', '&quot;': '"', '&#39;': "'", '&#x60;': '`', '&nbsp;': ' '  };
        return text.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x60;|&nbsp;/g, (entity: string) => entityMap[entity]); }

    return (
        <>
            {wrap ? (
                <div className=" position-relative w-max-content ">
                    <text>
                        {text ? <text dangerouslySetInnerHTML={{__html: text}}/> : children}
                    </text>
                    {getEditButton()}
                </div>
            ) : <>
                {text ? isHTMLString(text) ? <><text dangerouslySetInnerHTML={{__html: text}}/></> : text : children}
                {getEditButton()}
            </> }

            <Modal title={'Upravit'} open={editing} width={800} onCancel={() => setEditing(false)} styles={{
                body: {
                    maxHeight: '60vh',
                    overflow: 'hidden auto'
                }
            }} footer={<div>
                <Divider className={'m-2'}/>
                <Row key={'footer'} justify={"end"}>
                    <Col>
                        <Button onClick={() => setEditing(false)} className={'mr-2'}>Zrušiť</Button>
                        <Button type={'primary'} onClick={submit} loading={saving}>Uložiť</Button>
                    </Col>
                </Row></div>}>
                <Form ref={formRef as any} layout={'vertical'} initialValues={{text: decodeHTML(ReactDOMServer.renderToString(text || children))}}>
                    <Form.Item name={'text'} label={'Text'}
                               rules={[type === 'email' ?  {
                                   required: false,
                                   type: "email",
                                   message:
                                       'Neplatny E-mail!',
                               } : {required: true, message: 'Pole je povinné'}]}>
                        {{
                            plain: <Input/>,
                            wysiwyg: <Wysiwyg/>,
                            email: <Input type="email"/>,
                            text: <Input.TextArea rows={4}/>
                        }[type]}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
        ;
}

export default EditableText;
