import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

function Base() {
    return (
        <div>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    )
}

export default Base;
