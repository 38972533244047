import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Dropdown} from "antd";
import {DownOutlined} from '@ant-design/icons'


const menu = [
    {path: '/', name: 'Domov'},
    {path: '/novinky', name: 'Novinky'},
    {
        path: '/sip', name: 'DOMY-SIP', children: [
            {path: '/', name: 'Domov'},
            {path: '/panel', name: 'Panel'},
            {path: '/katalog', name: 'Katalog'},
            {path: '/reference', name: 'Reference'},
        ]
    },
    {path: '/modulovy-domy', name: 'Modulové Domy'},
    {path: '/mobilni-domy', name: 'Mobilní domy'},
    {path: '/realizace', name: 'Realizace'},
    {path: '/proc-viva-house', name: 'Proč Viva House'},
    {path: '/technologie', name: 'Technologie'},
    {path: '/kontakt', name: 'Kontakt'},
]

interface IProps {

}

const Header: React.FC<IProps> = ({}) => {

    const location = useLocation();

    useEffect(() => {
       setIsOpen(false)
    }, [location]);

    const [isOpen, setIsOpen] = useState(false);
    const toggleInfo = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="prague-header simple sticky-menu sticky-mobile-menu light"
                style={{backgroundColor: "black"}}>
            <div className="prague-logo">
                <Link to="/">
                    <img src="/img/logo.png" className="image_logo" alt="logo" style={{width: '214px'}}/>
                </Link>
            </div>
            <div className="prague-header-wrapper">
                <div className={"prague-navigation " + (isOpen ? 'open' : '')}>
                    <div className="pargue-navigation-wrapper">
                        <div className="prague-navigation-inner"
                             style={{
                                 touchAction: 'pan-y',
                                 userSelect: 'none',
                                 WebkitUserSelect: 'none',
                                 WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
                             }}>
                            <nav>
                                <ul className="main-menu">
                                    {menu.map(item => {
                                        if (item.children) {
                                            return <Dropdown menu={{
                                                items: item.children.map(child => ({
                                                    key: child.path,
                                                    label: <Link to={item.path + child.path}>{child.name}</Link>
                                                }))
                                            }}>
                                                <li className={'menu-item menu-item-type-custom menu-item-object-custom '+ (location.pathname.includes(item.path) && item.path.length > 1 ? 'current-menu-item' : '')}>
                                                    <a>
                                                        {item.name}
                                                        <DownOutlined className={'ml-1'}/>
                                                    </a>
                                                </li>
                                            </Dropdown>
                                        }
                                        return <li className={"menu-item menu-item-type-custom menu-item-object-custom " + (location.pathname.includes(item.path) && item.path.length > 1 ? 'current-menu-item' : '')}>
                                            <Link to={item.path}>{item.name}</Link>
                                        </li>
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={"prague-nav-menu-icon " + (isOpen ? 'active' : '')} onClick={() => setIsOpen(!isOpen)}>
                    <a><i></i></a></div>
            </div>

        </header>
    );
}

export default Header;
