import React, {useRef, useState} from 'react';
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Modal,
    Row,
    Tooltip,
    UploadFile
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import FileService from "../../service/app/FileService";
import {ISipHouse} from "../../interface/data/ISipHouse";
import FileUploader from "../../util/Uploader";
import Wysiwyg from "../../util/Wysiwyg";
import {DragOutlined, EyeOutlined, HomeOutlined} from "@ant-design/icons";
import {updateSetup} from "../../redux/slice/Setup";
import {AppDispatch} from "../../redux/store";
import SipHouseService from "../../service/app/SipHouseService";
import {IFile} from "../../interface/data/IFile";

interface IProps {
    house?: ISipHouse
    onFinish: (house?: ISipHouse) => void
}

const SipHouseForm: React.FC<IProps> = ({house, onFinish}) => {

    const formRef = useRef<FormInstance>()
    const [saving, setSaving] = useState(false)
    const [onHomePage, setOnHomePage] = useState(house?.onHomepage || false)
    const [isPublic, setIsPublic] = useState<boolean>(house?.isPublic === undefined ? true : house?.isPublic)

    const dispatch = useDispatch<AppDispatch>();
    const houses = useSelector((state: IAppState) => state.setup.sipHouses);

    const submit = async () => {
        formRef.current?.validateFields().then(async (values: {images:  (UploadFile & IFile)[], files:  (UploadFile & IFile)[], [param: string]: any}) => {
            setSaving(true)
            const newImages = await FileService.uploadAndSync(values.images, house?.images || []);
            const newFiles = await FileService.uploadAndSync(values.files, house?.files || []);

            try {
                const result = await SipHouseService.create({
                    id: house?.id,
                    ...values,
                    images: newImages,
                    files: newFiles,
                    onHomepage: onHomePage,
                    isPublic: isPublic
                })
                setSaving(false)
                let updatedHouses = [...houses]
                const index = updatedHouses.findIndex(h => h.id === result.id)
                if (index > -1){
                    updatedHouses[index] = result
                } else {
                    updatedHouses.push(result)
                }
                dispatch(updateSetup({sipHouses: updatedHouses}));
                onFinish()
            } catch {
                // setSaving(false)
                // return
            }
        })
    }

    return (
        <Modal title={'Upravit'} open={true} width={800} onCancel={() => onFinish()} styles={{
            body: {
                maxHeight: '60vh',
                overflow: 'hidden auto'
            }
        }} footer={<div>
            <Divider className={'m-2'}/>
            <Row key={'footer'} justify={"space-between"}>
            <Col>
                <Tooltip title={onHomePage ? 'Odebrat z domovské stránky' : 'Zobrazit na domovské stránce'}>
                    <Button type={onHomePage ? 'primary' : 'default'} icon={<HomeOutlined/>} className={'mr-2'}
                            onClick={() => setOnHomePage(!onHomePage)}/>
                </Tooltip>
                <Tooltip title={isPublic ? 'Skrýt tento dům' : 'Zveřejnit tento dům'}>
                    <Button type={isPublic ? 'primary' : 'default'} icon={<EyeOutlined/>} className={'mr-2'}
                            onClick={() => setIsPublic(!isPublic)}></Button>
                </Tooltip>
            </Col>
            <Col>
                <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                <Button type={'primary'} onClick={submit} loading={saving}>Uložiť</Button>
            </Col>
        </Row></div>}>
            <Form ref={formRef as any} layout={'vertical'} initialValues={{...house}}>
                <Form.Item name={'name'} label={'Název'} rules={[{required: true, message: 'Pole je povinné'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'priceBase'} label={'Cena - hrubá stavba:'}>
                    <InputNumber className={'w-100'} addonAfter={'Kč'}/>
                </Form.Item>
                <Form.Item name={'priceAll'} label={'Cena - na kľúč (holodom):'}>
                    <InputNumber className={'w-100'} addonAfter={'Kč'}/>
                </Form.Item>
                <Form.Item name={'area'} label={'Výměra'}>
                    <InputNumber className={'w-100'} addonAfter={'m2'}/>
                </Form.Item>
                <Form.Item name={'note'} label={'Popis'}>
                    <Wysiwyg/>
                </Form.Item>
                <Form.Item name={'files'} label={'Soubory'}>
                    <FileUploader previewIcon={false} accept={['.pdf', '.doc', '.docx']}/>
                </Form.Item>
                <Form.Item name={'images'} label={'Obrázky'}>
                    <FileUploader accept={['image/*']}/>
                </Form.Item>
                <Alert className={'py-2'} type={"info"}
                       description={<div className="f-small">První obrázek je vždy profilový obrázek <br/>Řadit
                           můžete
                           přetažením pomocí tlačítka s ikonou <DragOutlined/>
                </div>}/>
            </Form>
        </Modal>
    )
        ;
}

export default SipHouseForm;
