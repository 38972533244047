import {API_URL} from "../Fetcher";
import {ISetup} from "../../interface/redux/ISetup";
import axiosInstance from "../Interceptor";

const NAME = 'setup';

const SetupService = {
    load: async (): Promise<ISetup> => {
        try {
            const response = await axiosInstance.get<ISetup>(`${API_URL}/${NAME}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to load setup');
        }
    }
};

export default SetupService;
