import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Item, { ItemProps } from "./Item";

const SortableItem: FC<ItemProps> = (props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const dndStyle = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    const {style, ...rest} = props
    return (
        <Item
            ref={setNodeRef}
            style={{...style, ...dndStyle}}
            withOpacity={isDragging}
            {...rest}
            attributes={attributes}
            listeners={listeners}
        />
    );
};

export default SortableItem;
