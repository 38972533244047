import Fetcher, {IFetcherService, IFetcherSubService} from "../Fetcher";
import {IModularHouse} from "../../interface/data/IModularHouse";
import {IRealization} from "../../interface/data/IRealization";

const NAME = 'realization';

const RealizationService : IFetcherSubService<IRealization> = {
    create: (item) => {
        return Fetcher.create(item, NAME)
    },

    update:  (id, item) => {
        return Fetcher.update(id, item, NAME)
    },

    list: () => {
        return Fetcher.list(NAME)
    },

    get: (id) => {
        return Fetcher.get(id, NAME)
    },

    delete: (id) => {
        return Fetcher.delete(id, NAME)
    }
};

export default RealizationService;
