import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScriptManagerProps {
    scripts: { src: string; async?: boolean; defer?: boolean }[];
}

const ScriptManager: React.FC<ScriptManagerProps> = ({ scripts }) => {
    const location = useLocation();

    useEffect(() => {
        const loadScriptsSequentially = async () => {
            for (const { src, async = true, defer = true } of scripts) {
                if (!document.querySelector(`script[src="${src}"]`)) {
                    await new Promise((resolve, reject) => {
                        const script = document.createElement('script');
                        script.src = src;
                        script.async = async;
                        script.defer = defer;

                        script.onload = () => {
                            resolve(true);
                        };

                        script.onerror = (e) => {
                            console.error(`Script load error: ${src}`, e);
                            reject(e);
                        };

                        document.body.appendChild(script);
                    });
                }
            }

            // Manually trigger DOMContentLoaded event
            const domContentLoadedEvent = new Event('DOMContentLoaded', {
                bubbles: true,
                cancelable: true
            });
            document.dispatchEvent(domContentLoadedEvent);

            // Manually trigger jQuery ready event if jQuery is loaded
            if ('jQuery' in window) {
                const jquery = window.jQuery as any;
                jquery(document).triggerHandler('ready');
            }

            // Manually trigger load event
            const loadEvent = new Event('load', {
                bubbles: true,
                cancelable: true
            });
            window.dispatchEvent(loadEvent);
        };

        loadScriptsSequentially();

        // Clean up: remove the scripts when the component unmounts or when the location changes
        return () => {
            scripts.forEach(({ src }) => {
                const script = document.querySelector(`script[src="${src}"]`);
                if (script) {
                    document.body.removeChild(script);
                }
            });
        };
    }, [location, scripts]);

    return null;
};

export default ScriptManager;
