import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from "../page/Home";
import News from "../page/News";
import ModularHouseDetail from "../page/detail/ModularHouseDetail";
import Sip from "../page/Sip";
import SipCatalog from "../page/SipCatalog";
import SipPanel from "../page/SipPanel";
import SipRealizations from "../page/SipReferences";
import ModularHouses from "../page/ModularHouses";
import Contact from "../page/Contact";
import About from "../page/About";
import Technology from "../page/Technology";
import SipRealizationDetail from "../page/detail/SipRealizationDetail";
import References from "../page/References";
import RealizationDetail from "../page/detail/RealizationDetail";
import {Row} from "antd";
import MobileHouses from "../page/MobileHouses";
import NotFound from "../components/NotFound";
import SipHouseDetail from "../page/detail/SipHouseDetail";

function Body() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/mobilni-domy" element={<MobileHouses/>}/>
                <Route path="/realizace/:name" element={<RealizationDetail/>}/>
                <Route path="/realizace" element={<References/>}/>
                <Route path="/sip/katalog/:name" element={<SipHouseDetail/>}/>
                <Route path="/sip/katalog" element={<SipCatalog/>}/>
                <Route path="/sip/panel" element={<SipPanel/>}/>
                <Route path="/sip/reference/:name" element={<SipRealizationDetail/>}/>
                <Route path="/sip/reference" element={<SipRealizations/>}/>
                <Route path="/sip" element={<Sip/>}/>
                <Route path="/novinky" element={<News/>}/>
                <Route path="/modulovy-domy/:name" element={<ModularHouseDetail/>}/>
                <Route path="/modulovy-domy" element={<ModularHouses/>}/>
                <Route path="/kontakt" element={<Contact/>}/>
                <Route path="/proc-viva-house" element={<About/>}/>
                <Route path="/technologie" element={<Technology/>}/>
                <Route path={'/*'} element={<div>
                    <NotFound/>
                </div>}/>
            </Routes>
        </div>
    )
}

export default Body;
