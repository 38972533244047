import React, {useState} from 'react';
import {Alert, Checkbox, Col, Form, Input, Row} from "antd";
import {IContact} from "../interface/data/IContact";
import ContactService from "../service/app/ContactService";
import {useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import EditableText, {LANGUAGE} from "../components/EditableText";

function Contact() {

    const [sent, setSent] = useState<undefined | IContact>();


    const texts = useSelector((state: IAppState) => state.setup.editableTexts);

    const email = texts.find(t => t.id === 'footer.contact.email')?.versions.find(v => v.language === LANGUAGE)?.text || 'info@vivahouse.eu'
    const phone = texts.find(t => t.id === 'footer.contact.phone')?.versions.find(v => v.language === LANGUAGE)?.text || '737 255 647'

    const sendContact = (values: IContact) => {
        ContactService.create(values).then((contact) => setSent(contact))
    }

    return (
        <div>
            <div className="container-fluid no-padd">
                <div className="row-fluid no-padd">
                    <div className="col-sm-12 no-padd">
                        <div className="position-relative h-100">
                            <div className="container-fluid top-banner no-padd big light no-marg-bottom vindow-height"
                                 style={{background: 'url("img/kontakt.jpg") center center / cover'}}><span
                                className="overlay"></span>
                                <div className="content">
                                    <div className="prague-svg-animation-text"></div>
                                    <h1 className="title">Kontakt</h1></div>
                                <div className="top-banner-cursor"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container no-padd margin-lg-75t margin-sm-30t margin-md-0t">
                <div className="row-fluid">
                    <div
                        className="margin-md-90t margin-sm-20t col-sm-12 col-lg-offset-0 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-0 col-xs-12 padd-only-xs">
                        <div className="heading left dark">
                            <div className="subtitle">KONTAKT</div>
                            <h2 className="title">Fakturační údaje</h2>
                            <div className="content"><p> Sídlo: Bečovská 1648, 104 00 Praha 10 <br/> Česká
                                republika <br/></p></div>
                        </div>
                        <Row className="row-fluid vc_custom_1488483852815">
                            <Col xs={12} sm={8} className="no-padd no-padd">
                                <div className="adddress-block">
                                    <div className="address-block-outer"><span className="separator"></span> <h4
                                        className="address-title">EMAIL / JMÉNO</h4> <p><a
                                        className="position-relative"
                                        href={'mailto:' + email}><EditableText
                                        type={"email"} wrap={true} code={'footer.contact.email'}>
                                        info@vivahouse.eu
                                    </EditableText></a>
                                        <a className="kontaktsmall-email position-relative">
                                            <EditableText type={"plain"} wrap={true} code={'footer.contact.name'}>
                                                Ing. Monika Vavreková
                                            </EditableText>
                                        </a></p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} className="no-padd no-padd">
                                <div className="adddress-block">
                                    <div className="address-block-outer"><span className="separator"></span> <h4
                                        className="address-title">TEL.Č.</h4>
                                        <a className="position-relative" href={"tel:+420" + phone.trim()}>
                                            <EditableText type={"plain"} wrap={true} code={'footer.contact.phone'}>
                                                737 255 647
                                            </EditableText>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xs-12 margin-lg-90t margin-sm-minus margin-md-190t no-padd">
                        <h2 className="d-flex justify-content-center title">Napište nám</h2>
                        <div className="vc_column-inner vc_custom_1488483852815">
                            <div className="prague-formidable vc_formidable">
                                <div className="frm_forms with_frm_style frm_style_formidable-style-2-2"
                                     id="frm_form_3_container">
                                    <Form size={"small"} className=" contact-form mb-3" layout={"horizontal"}
                                          onFinish={sendContact}>
                                        <Form.Item name={'name'} rules={[{required: true, message: 'Vyplňte prosím'}]}
                                                   className={'mb-3'}>
                                            <Input placeholder={'Jméno:'} className={'border-radius-10 input'}/>
                                        </Form.Item>
                                        <Form.Item name={'email'} rules={[{required: true, message: 'Vyplňte prosím'}]}
                                                   className={'mb-3'}>
                                            <Input placeholder={'Email:'} className={'border-radius-10 input'}
                                                   type={'email'}/>
                                        </Form.Item>
                                        <Form.Item name={'phone'} className={'mb-3'}>
                                            <Input placeholder={'Tel.č.:'} className={'border-radius-10 input'}/>
                                        </Form.Item>
                                        <Form.Item name={'message'} className={'mb-3'}
                                                   rules={[{required: true, message: 'Vyplňte prosím'}]}>
                                            <Input.TextArea cols={20} placeholder={'Zpráva:'}
                                                            className={'border-radius-10 input'}/>
                                        </Form.Item>
                                        <Form.Item name={'gdpr'} valuePropName={'checked'} className={'mb-3'}
                                                   rules={[{required: true, message: 'Souhlaste prosím s GDPR.'}]}>
                                            <Checkbox>
                                                <span> <span
                                                    style={{lineHeight: 'unset'}}> Souhlasím se zpracováním{' '}
                                                    <a target="_blank"
                                                       href="/docs/Ochrana osobních údajů.pdf" style={{
                                                        color: 'rgb(34, 34, 238)',
                                                        textDecoration: 'underline'
                                                    }}> osobních údajů </a>{' '} a{' '} <a target="_blank"
                                                                                           href="/docs/GDPR dokument.pdf"
                                                                                           style={{
                                                                                               color: 'rgb(34, 34, 238)',
                                                                                               textDecoration: 'underline'
                                                                                           }}> GDPR </a> </span> </span>
                                            </Checkbox>
                                        </Form.Item>
                                        {sent && sent.success && (
                                            <Alert type={'success'} description={sent.message}/>
                                        )}
                                        {sent && !sent.success && (
                                            <Alert type={'error'} description={sent.message}/>
                                        )}
                                        <button name="submit" value="Submit" id="submit_btn2" type="submit"
                                                className="ant-btn css-djtmh8 ant-btn-default submit-button border-radius-10 send-email viva-button">
                                            <span>Odeslat</span></button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
;
}

export default Contact;
