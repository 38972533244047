import React, {ReactNode, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToHash: React.FC<{ children: ReactNode }> = ({children}) => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({behavior: 'smooth', block: 'center' });
            }
        } else {
            window.scrollTo({ top: 0 })
        }
    }, [location]);
    return <>{children}</>;
};
export default ScrollToHash;
