import React from 'react';
import EditableText, {LANGUAGE} from "../components/EditableText";
import {useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import {Col, Row} from "antd";
import EditableImage from "../components/EditableImage";

function Footer() {

    const texts = useSelector((state: IAppState) => state.setup.editableTexts);

    const email = texts.find(t => t.id === 'footer.contact.email')?.versions.find(v => v.language === LANGUAGE)?.text || 'info@vivahouse.eu'
    const phone = texts.find(t => t.id === 'footer.contact.phone')?.versions.find(v => v.language === LANGUAGE)?.text || '737 255 647'


    return (
        <div className="position-relative ">
            <EditableImage code={'footer.background'}
                           defaultUrl={'/img/lyon_footer.jpg'}
                           render={(url) => (
                               <footer className="prague-footer default"
                                       style={{
                                           backgroundImage: 'url("' + url + '")',
                                           backgroundPosition: 'center center',
                                           backgroundSize: 'cover',
                                           backgroundRepeat: 'no-repeat'
                                       }}>
                                   <div className="footer-content-outer">
                                       <div className="footer-top-content">
                                           <div className="prague-footer-main-block">
                                               <div className="prague-logo"><a href=""><img src="/img/logo.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt="logo"
                                                                                            style={{width: '214px'}}/>
                                               </a>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="container no-padd margin-lg-75t margin-sm-30t margin-md-0t">
                                           <Row gutter={[36, 24]}>
                                               <Col xs={24} sm={12} md={12}
                                                    className="  margin-md-90t margin-sm-20t col-sm-12 col-lg-offset-0 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-0 col-xs-12 padd-only-xs">
                                                   <div className="heading  left dark p-0"><h2
                                                       className="title font-white position-relative"><EditableText
                                                       type={"plain"} code={'footer.title'}>
                                                       Navštivte náš vzorový dům
                                                   </EditableText></h2>
                                                       <div className="content font-white">
                                                           <EditableText type={"wysiwyg"} wrap={true}
                                                                         code={'footer.description'}>
                                                               <p>Přijďte si prohlédnout náš vzorový dům v ul.
                                                                   Přátelství, Praha
                                                                   10-Uhříněves, naproti Penny Marketu a přesvědčte se
                                                                   na vlastní oči. </p>
                                                               <p> Návštěva vzorového domu Vás k ničemu nezavazuje.
                                                                   Výroba domu trvá 15
                                                                   týdnů.</p><p>Prohlídky jsou možné po telefonické
                                                               domluvě! <span
                                                                   style={{color: "rgb(126, 125, 125)"}}>tel: 737 255 647.</span>
                                                           </p>
                                                           </EditableText>
                                                       </div>
                                                   </div>
                                                   <div className="row-fluid vc_custom_1488483852815">
                                                       <div className="col-sm-4 no-padd no-padd">
                                                           <div className="column-inner">
                                                               <div className="adddress-block  ">
                                                                   <div className="address-block-outer"><span
                                                                       className="separator"></span>
                                                                       <h4 className="address-title font-white">Telefonní
                                                                           číslo</h4><p><a
                                                                           className="font-white position-relative"
                                                                           href={"tel:+420" + phone.trim()}><EditableText
                                                                           type={"plain"}
                                                                           wrap={true}
                                                                           code={'footer.contact.phone'}>
                                                                           737 255 647
                                                                       </EditableText></a></p></div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div className="col-sm-4 no-padd no-padd">
                                                           <div className="adddress-block">
                                                               <div className="address-block-outer"><span
                                                                   className="separator"></span><h4
                                                                   className="address-title font-white">Email /
                                                                   Jméno</h4><p
                                                                   className="font-white"><a
                                                                   className="font-white position-relative"
                                                                   href={'mailto:' + email}><EditableText
                                                                   type={"email"} wrap={true}
                                                                   code={'footer.contact.email'}>
                                                                   info@vivahouse.eu
                                                               </EditableText></a><br/><a
                                                                   className="position-relative"><EditableText
                                                                   type={"plain"} wrap={true}
                                                                   code={'footer.contact.name'}>
                                                                   Ing. Monika Vavreková
                                                               </EditableText></a></p></div>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </Col>
                                               <Col xs={24} sm={12} md={12}
                                                    className="col-sm-12 col-md-6 col-xs-12  margin-sm-40t margin-md-190t no-padd">
                                                   <iframe
                                                       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d320.37787997407264!2d14.612079295727119!3d50.029664102194765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b8c4be4f93185%3A0xfc4d4f394352966b!2zUMWZw6F0ZWxzdHbDrSA4MzEvMTE0LCAxMDQgMDAgUHJhaGEgMjItVWjFmcOtbsSbdmVzLCBDemVjaGlh!5e0!3m2!1sen!2ssk!4v1724184410231!5m2!1sen!2ssk"
                                                       loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                                       style={{
                                                           width: '100%',
                                                           height: '350px',
                                                           marginBottom: '0px'
                                                       }}></iframe>
                                               </Col>
                                           </Row>
                                       </div>
                                       <div className="footer-bottom-content">
                                           <div className="footer-copyright">
                                               <p>© {(new Date()).getFullYear()} Vivahouse.eu s.r.o. Všechna
                                                   práva vyhrazena.</p>
                                           </div>
                                       </div>
                                   </div>
                               </footer>
                           )}/>

        </div>
    );
}

export default Footer;
