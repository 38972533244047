import React, {useState} from 'react';
import {Button, Col, Row} from "antd";
import {Link} from "react-router-dom";
import FileService from "../service/app/FileService";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import RecordsOrderService from "../service/app/RecordsOrderService";
import ModularHouseCard from "./card/ModularHouseCard";
import {IModularHouse} from "../interface/data/IModularHouse";
import {PlusOutlined} from "@ant-design/icons";
import ModularHouseForm from "./form/ModularHouseForm";
import {updateSetup} from "../redux/slice/Setup";
import {AppDispatch} from "../redux/store";
import Sorter from "../util/Sorter";

function News() {

    const ORDER = 'news'
    const user = useSelector((state: IAppState) => state.setup.user);
    const houses = useSelector((state: IAppState) => state.setup.modularHouses).filter(h => h.isNew && ((h.isPublic !== false) || user));
    const orderList = useSelector((state: IAppState) => state.setup.recordsOrder);
    const sortedHouses = RecordsOrderService.sort(ORDER, orderList, houses);
    const dispatch = useDispatch<AppDispatch>();

    const [edit, setEdit] = useState<boolean | IModularHouse>(false);

    const onSortEnd = (sorted: IModularHouse[]) => {
        dispatch(updateSetup({recordsOrder: RecordsOrderService.sortUpdate(ORDER, orderList, sorted)}));
        RecordsOrderService.create({id: ORDER, order: sorted.map(h => h.id)}).then(r => {})
    }

    return (
        <div>
            <div className="container no-padd margin-lg-145t margin-lg-145b margin-sm-100t margin-sm-70b">
                <Row justify={'space-around'} align={'middle'} wrap={false}>
                    <div></div>
                    <h1 className="d-flex justify-content-center mb-2 title">Novinky</h1>
                    <div>
                        {user &&  <Row justify={"end"} align={'middle'}>
                            <Button shape={"circle"} icon={<PlusOutlined/>} onClick={() => setEdit(true)}/>
                            {edit && <ModularHouseForm house={!(typeof edit === 'boolean') ? edit : undefined} defaultNew={true}
                                                       onFinish={() => setEdit(false)}/>}
                        </Row>}
                    </div>
                </Row>
                <div className="d-flex justify-content-center mb-2 text-center position-relative">
                    <text><p>Modulové dřevostavby jsou moderním, cenově dostupným řešením bydlení. Všechny domy jsou
                        vyhotoveny z kvalitních materiálů v nízkoenergetickém standardu. Poskytují zdravé prostředí a
                        dokonalý komfort, který uspokojí i náročného klienta</p></text>
                </div>
                <div className="row-fluid">
                    <div className="col-sm-12 no-padd">
                        <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more"
                             data-start-page="1" data-max-page="3">
                            <Row justify={"space-around"} className="w-100 m-0" gutter={[32, 32]}>
                                <Sorter onSortEnd={onSortEnd} items={sortedHouses}
                                        renderItem={(house, props, handleProps) => <Col xs={24} sm={12} lg={8}
                                                                                        {...props}><ModularHouseCard
                                            house={house} onEdit={setEdit} handleProps={handleProps}/></Col>}/>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;
