import React from 'react';
import {Collapse, Image} from "antd";
import {useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import {useParams} from "react-router-dom";
import FileService from "../../service/app/FileService";
import {EyeOutlined} from "@ant-design/icons";
import NotFound from "../../components/NotFound";

const SipRealizationDetail: React.FC = () => {

    const {name} = useParams();
    const houses = useSelector((state: IAppState) => state.setup.sipRealizations).filter(h => h.isPublic);;
    const house = houses.find(h => h.location === name)


    if (!house) {
        return <NotFound/>
    }

    return (
        <div>
            <div>
                <div className="container-fluid no-padd">
                    <div className="row-fluid no-padd">
                        <div className="col-sm-12 no-padd">
                            <div className="container-fluid top-banner no-padd big light no-marg-bottom"
                                 style={{background: "url('" + FileService.buildSrcUrl(house.images[0]?.uid) + "') center center / cover"}}>
                                <span className="overlay"></span>
                                <div className="content"><h1 className="title">{house.location}</h1></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid no-padd">
                    <div className="row-fluid no-padd">
                        <div className="col-sm-12 no-padd">
                            <div className="project-detail-gallery-outer">
                                <Image.PreviewGroup>
                                    <div className="gallery-grid">
                                        {house.images.map(image => <div className="gallery-grid-item">
                                            <div className="detail-gallery-item">
                                                <div className="detail-gallery-item-img">
                                                    <Image preview={{mask: ( <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}> <EyeOutlined style={{ marginRight: '8px' }} /> Náhled </div> ),}} alt={image.name} src={FileService.buildSrcUrl(image.uid)}/>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </Image.PreviewGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default SipRealizationDetail;
