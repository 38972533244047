import React from 'react';
import {Col, Image, Row} from "antd";
import {useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import {useParams} from "react-router-dom";
import FileService from "../../service/app/FileService";
import {FilePdfOutlined} from "@ant-design/icons";
import NotFound from "../../components/NotFound";

const SipHouseDetail: React.FC = () => {

    const {name} = useParams();
    const houses = useSelector((state: IAppState) => state.setup.sipHouses).filter(h => h.isPublic);
    const house = houses.find(h => h.name === name)


    if (!house) {
        return <NotFound/>
    }

    return (
        <div>
            <div>
                <div
                    className="shop-container container padd-only-xs product-template-default single single-product postid-2072 woocommerce woocommerce-page woocommerce-no-js wpb-js-composer js-comp-ver-5.6 responsive">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 margin-lg-90b margin-sm-60b">
                            <div className="woocommerce-notices-wrapper"></div>
                            <div id="Letný dom v krokusoch 2"
                                 className="post-2072 product type-product status-publish has-post-thumbnail product_cat-acssessories product_tag-casual product_tag-modern first instock virtual purchasable product-type-simple">
                                <div className="flex-item ">
                                    <Image.PreviewGroup>
                                        <div
                                            className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-3 images"
                                            data-columns="3">
                                            <div className="flex-viewport">
                                                <figure className="woocommerce-product-gallery__wrapper">
                                                    <div
                                                        className="woocommerce-product-gallery__image flex-active-slide">
                                                        <Image
                                                            preview={{mask: undefined}}
                                                            draggable="false"
                                                            className=" attachment-shop_single size-shop_single firstImg cursor-pointer"
                                                            src={FileService.buildSrcUrl(house.images[0]?.uid)}/>
                                                    </div>
                                                </figure>
                                            </div>
                                            <ol className="flex-control-nav flex-control-thumbs">
                                                {house.images.map(image => <li>
                                                    <Image
                                                        preview={{mask: undefined}}
                                                        draggable="false"
                                                        className=" attachment-full size-full cursor-pointer"
                                                        src={FileService.buildSrcUrl(image.uid)}/>
                                                </li>)}
                                            </ol>
                                            <div className="mt-4 color-brown">
                                                {house.files.map(file =>
                                                    <div><a
                                                        href={FileService.buildSrcUrl(file.uid)}
                                                        target="_blank"><FilePdfOutlined className={'mr-1'}/>{file.name}</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Image.PreviewGroup>
                                    <div className="summary entry-summary">
                                        <h2 className="product_title entry-title">{house.name}</h2>
                                        <Row className="mt-4">
                                            <Col><a
                                                className="prague-services-link a-btn-2 creative anima" href="/kontakt"
                                                target="_self" style={{marginLeft: '8px', marginBottom: '10px'}}><span
                                                className="a-btn-line"></span>MÁM ZÁJEM </a></Col>
                                            <Col></Col>
                                        </Row>
                                        <div className="woocommerce-product-details__short-description">
                                            {house.priceBase && <p>Cena Hrubá
                                                stavba: <strong>{house.priceBase?.toLocaleString()} Kč</strong> bez DPH
                                            </p>}
                                            {house.priceAll && <p>Cena na kľúč
                                                (holodom): <strong>{house.priceAll?.toLocaleString()} Kč</strong> vrátane
                                                DPH</p>}
                                            {house.area && <p>Úžitková plocha: <strong>{house.area} m2</strong></p>}
                                            <div><br/><strong>
                                                <p className="font-bold"
                                                   dangerouslySetInnerHTML={{__html: house.note}}></p>
                                            </strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default SipHouseDetail;
