import React, {useRef, useState} from 'react';
import {Button, Col, Divider, Form, FormInstance, Image, Modal, Row, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import FileService from "../../service/app/FileService";
import {IModularHouse} from "../../interface/data/IModularHouse";
import ModularHouseService from "../../service/app/ModularHouseService";
import {updateSetup} from "../../redux/slice/Setup";
import {AppDispatch} from "../../redux/store";

interface IProps {
    house?: IModularHouse
    onFinish: () => void
}

const ModularHouseCoverForm: React.FC<IProps> = ({house, onFinish}) => {

    const formRef = useRef<FormInstance>()
    const [saving, setSaving] = useState(false)

    const dispatch = useDispatch<AppDispatch>();
    const houses = useSelector((state: IAppState) => state.setup.modularHouses);

    const submit = async () => {
        formRef.current?.validateFields().then(async (values: {cover: string}) => {
            setSaving(true)

            const newHouse = houses.find(h => h.id === values.cover)
            try {
                const result = await ModularHouseService.create({
                    id: newHouse?.id,
                    onHomepageCover: Math.max(...houses.map(h => h.onHomepageCover || 0)) + 1
                })
                setSaving(false)
                let updatedHouses = [...houses]
                const index = updatedHouses.findIndex(h => h.id === result.id)
                if (index > -1) {
                    updatedHouses[index] = result
                } else {
                    updatedHouses.push(result)
                }
                dispatch(updateSetup({modularHouses: updatedHouses}));
                onFinish()
            } catch {
                // setSaving(false)
                // return
            }
        })
    }

    return (
        <Modal title={'Upravit'} open={true} width={800} onCancel={() => onFinish()} styles={{
            body: {
                maxHeight: '60vh',
                overflow: 'hidden auto'
            }
        }} footer={<div>
            <Divider className={'m-2'}/>
            <Row key={'footer'} justify={"end"}>
                <Col>
                    <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                    <Button type={'primary'} onClick={submit} loading={saving}>Uložiť</Button>
                </Col>
            </Row></div>}>
            <Form ref={formRef as any} layout={'vertical'} initialValues={{cover: house?.id}}>
                <Form.Item label={'Dom'} name={'cover'}>
                    <Select style={{height: 100}} options={houses.map(house => ({label: <Row justify={'space-between'}>
                            {house.name}
                            <Image height={90} src={FileService.buildSrcUrl(house.images[0]?.uid)}></Image>
                        </Row>, value: house.id}))}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ModularHouseCoverForm;
