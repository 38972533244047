import Fetcher, {IFetcherSubService} from "../Fetcher";
import {IContact} from "../../interface/data/IContact";

const NAME = 'contact-form';

const ContactService : IFetcherSubService<IContact> = {
    create: (item) => {
        return Fetcher.create(item, NAME)
    },

    update:  (id, item) => {
        return Fetcher.update(id, item, NAME)
    },

    list: () => {
        return Fetcher.list(NAME)
    },

    get: (id) => {
        return Fetcher.get(id, NAME)
    },

    delete: (id) => {
        return Fetcher.delete(id, NAME)
    }
};

export default ContactService;
