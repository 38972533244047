import Fetcher, {IFetcherSubService} from "../Fetcher";
import {ISipHouse} from "../../interface/data/ISipHouse";

const NAME = 'sip-house';

const SipHouseService : IFetcherSubService<ISipHouse> = {
    create: (item) => {
        return Fetcher.create(item, NAME)
    },

    update:  (id, item) => {
        return Fetcher.update(id, item, NAME)
    },

    list: () => {
        return Fetcher.list(NAME)
    },

    get: (id) => {
        return Fetcher.get(id, NAME)
    },

    delete: (id) => {
        return Fetcher.delete(id, NAME)
    }
};

export default SipHouseService;
