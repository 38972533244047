import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import RecordsOrderService from "../service/app/RecordsOrderService";
import RealizationCard from "./card/RealizationCard";
import {Button, Col, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {IRealization} from "../interface/data/IRealization";
import RealizationForm from "./form/RealizationForm";
import SipRealizationForm from "./form/SipRealizationForm";
import Sorter from "../util/Sorter";
import ModularHouseCard from "./card/ModularHouseCard";
import {AppDispatch} from "../redux/store";
import {IModularHouse} from "../interface/data/IModularHouse";
import {updateSetup} from "../redux/slice/Setup";
import EditableText from "../components/EditableText";

function References() {
    const ORDER = 'realization'
    const user = useSelector((state: IAppState) => state.setup.user);
    const houses = useSelector((state: IAppState) => state.setup.realizations).filter(h => (h.isPublic !== false || user));
    const orderList = useSelector((state: IAppState) => state.setup.recordsOrder);

    let sortedHouses = RecordsOrderService.sort(ORDER, orderList, houses);
    const [edit, setEdit] = useState<boolean | IRealization>(false);

    const dispatch = useDispatch<AppDispatch>();

    const onSortEnd = (sorted: IRealization[]) => {
        dispatch(updateSetup({recordsOrder: RecordsOrderService.sortUpdate(ORDER, orderList, sorted)}));
        RecordsOrderService.create({id: ORDER, order: sorted.map(h => h.id)}).then(r => {
        })
    }

    return (
        <div>
            <div>
                <div className="container-fluid no-padd">
                    <div className="row-fluid margin-lg-145t margin-sm-100t">
                        <Row wrap={false} justify={"space-around"} align={"middle"} className="container padd-only-xs">
                            <div></div>
                            <div className="w-100"><h1 className="title d-flex justify-content-center"
                                                       style={{marginBottom: "10px"}}>Realizace</h1>
                                <div className=" d-flex justify-content-center" style={{marginBottom: "20px"}}>
                                    <div className="text-center">
                                        <EditableText type={"wysiwyg"} wrap={true}
                                                      code={'realizations.description'}>
                                            <p>Kliknutím na obrázek se vám zobrazí více info</p>
                                        </EditableText>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {user && (
                                    <Row justify={"end"} align={'middle'}>
                                        <Button shape={"circle"} icon={<PlusOutlined/>} onClick={() => setEdit(true)}/>
                                        {edit && <RealizationForm house={!(typeof edit === 'boolean') ? edit : undefined}
                                                                  onFinish={() => setEdit(false)}/>}
                                    </Row>
                                )}
                            </div>

                        </Row>
                        <div className="col-sm-12 mb-4">
                            <div className="vc_column-inner">
                                <div data-unique-key="14ad5b356d917aa464e6341724c17a46" className="js-load-more"
                                     data-start-page="1" data-max-page="1" data-next-link="">
                                    <div className="gallery-grid">
                                        <Sorter onSortEnd={onSortEnd} items={sortedHouses}
                                                renderItem={(house, props, handleProps) => <div
                                                    className="gallery-grid-item" {...props}>
                                                    <RealizationCard
                                                        house={house} onEdit={setEdit} handleProps={handleProps}/>
                                                </div>}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default References;
