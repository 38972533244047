import axiosInstance from "./Interceptor";
import {IFileObject} from "../interface/data/IFileObject";
import {Key} from "react";


const API_TEST_URL = 'http://vivahouse/api';
// export const API_URL = API_TEST_URL;
export const API_URL = 'https://www.vivahouse.eu/api';

type Item = any

export interface IFetcherService<I> {
    create(item: Partial<I>, serviceName: string): Promise<I>;

    update(id: Key, item: Partial<I>, serviceName: string): Promise<I>;

    get(id: Key, serviceName: string): Promise<I>;

    list(serviceName: string): Promise<I[]>;

    delete(id: Key, serviceName: string): Promise<void>;

    upload(data: FormData, serviceName: string): Promise<IFileObject[]>;

    [property: string]: any
}

export interface IFetcherSubService<I> {
    create(item: Partial<I>): Promise<I>;

    update(id: Key, item: Partial<I>): Promise<I>;

    get(id: Key): Promise<I>;

    list(): Promise<I[]>;

    delete(id: Key): Promise<void>;

    [property: string]: any
}

const Fetcher: IFetcherService<any> = {
    create: async (item: any, serviceName): Promise<any> => {
        try {
            const response = await axiosInstance.post<Item>(`${API_URL}/${serviceName}`, item);
            return response.data;
        } catch (error) {
            throw new Error('Failed to create item');
        }
    },

    update: async (id: number, item: any, serviceName): Promise<any> => {
        try {
            const response = await axiosInstance.post<Item>(`${API_URL}/${serviceName}/${id}`, item);
            return response.data;
        } catch (error) {
            throw new Error('Failed to update item');
        }
    },

    get: async (id: number, serviceName): Promise<any> => {
        try {
            const response = await axiosInstance.get<Item>(`${API_URL}/${serviceName}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to get item');
        }
    },

    list: async (serviceName): Promise<any[]> => {
        try {
            const response = await axiosInstance.get<any[]>(`${API_URL}/${serviceName}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to list items');
        }
    },

    delete: async (id: number, serviceName): Promise<void> => {
        try {
            await axiosInstance.delete(`${API_URL}/${serviceName}/${id}`);
        } catch (error) {
            throw new Error('Failed to delete item');
        }
    },
    upload: async (data: FormData, serviceName: string): Promise<IFileObject[]> => {
        try {
            const response = await axiosInstance.post(`${API_URL}/${serviceName}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw new Error('Failed to delete item');
        }
    }
};


export default Fetcher;
