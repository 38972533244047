import {CSSProperties, forwardRef, HTMLAttributes, JSX} from 'react';
import {DraggableAttributes, UniqueIdentifier} from "@dnd-kit/core";
import {SyntheticListenerMap} from "@dnd-kit/core/dist/hooks/utilities";

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    id: UniqueIdentifier;
    withOpacity?: boolean;
    isDragging?: boolean;
    renderItem: (props: any, handleProps: any) => JSX.Element;
    attributes?: DraggableAttributes
    listeners?: SyntheticListenerMap
};

const Item = forwardRef<HTMLDivElement, ItemProps>(({ id, withOpacity, isDragging, style, renderItem, listeners, attributes, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        minWidth: '350px',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: '#ffffff',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        ...style,
    };

    return renderItem({ref, style: inlineStyles , ...props}, {...listeners, ...attributes});
});

export default Item;
