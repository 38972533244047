import {Button, Col, Popconfirm, Row} from "antd";
import {DeleteOutlined, DragOutlined, EditOutlined} from "@ant-design/icons";
import React from "react";
import {IUser} from "../../interface/security/IUser";

const HouseCardActions = (props: {
    user: IUser | undefined,
    onClick: () => void,
    handleProps: any,
    onConfirm: () => void,
    deleting: string | undefined,
    id: string
}) => {
    return <>
        {props.user && (
            <div className="position-absolute"
                 style={{top: "5px", right: "5px", zIndex: 1}}>

                <Row className="flex-column p-0" gutter={[6, 6]}>
                    <Col>
                        <Button shape={"circle"} icon={<EditOutlined/>}
                                onClick={e => { e.stopPropagation(); e.preventDefault(); props.onClick()}}/>
                    </Col>
                    <Col>
                        <Button shape={"circle"} icon={<DragOutlined/>}
                                style={{cursor: "move"}} {...props.handleProps}/>
                    </Col>
                    <Col>
                        <Popconfirm title={"Opravdu smazat?"} okText={"Ano"}
                                    onConfirm={props.onConfirm}
                                    cancelText={"ne"}>
                            <Button shape={"circle"} danger={true}
                                    loading={props.deleting === props.id}
                                    icon={<DeleteOutlined/>} onClick={e => { e.stopPropagation()}}/>
                        </Popconfirm>
                    </Col>
                </Row>
            </div>
        )}
    </>;
}

export default HouseCardActions
