// Create an Axios instance
import axios from "axios";
import {notification} from "antd";

const axiosInstance = axios.create({
    baseURL: 'http://vivahouse/api' // Replace with your API base URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('_AKey');

        // If token is available, set it in the headers
        if (token) {
            config.headers.Token = token;
        }

        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(function (response) {
    // Optional: Do something with response data
    return response;
}, function (error) {
    const { response } = error;
    const token = localStorage.getItem('_AKey');
    if (response && response.status === 401 && token) {
        localStorage.removeItem('_AKey')
        window.location.reload()
    }
    notification['error']({
        message: 'Omlouváme se, ale na webu se objevila chyba',
    });
    return Promise.reject(error);
});

export default axiosInstance