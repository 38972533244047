import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import RecordsOrderService from "../service/app/RecordsOrderService";
import SipRealizationCard from "./card/SipRealizationCard";
import {Button, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import SipRealizationForm from "./form/SipRealizationForm";
import {ISipRealization} from "../interface/data/ISipRealization";
import {AppDispatch} from "../redux/store";
import {updateSetup} from "../redux/slice/Setup";
import Sorter from "../util/Sorter";

function SipRealizations() {

    const ORDER = 'sip-realization'
    const user = useSelector((state: IAppState) => state.setup.user);
    const houses = useSelector((state: IAppState) => state.setup.sipRealizations).filter(h => (h.isPublic !== false || user));
    const orderList = useSelector((state: IAppState) => state.setup.recordsOrder);

    let sortedHouses = RecordsOrderService.sort(ORDER, orderList, houses);
    const [edit, setEdit] = useState<boolean | ISipRealization>(false);

    const dispatch = useDispatch<AppDispatch>();

    const onSortEnd = (sorted: ISipRealization[]) => {
        dispatch(updateSetup({recordsOrder: RecordsOrderService.sortUpdate(ORDER, orderList, sorted)}));
        RecordsOrderService.create({id: ORDER, order: sorted.map(h => h.id)}).then(r => {
        })
    }

    return (
        <div>
            <div>
                <div className="container-fluid">
                    <div className="row-fluid margin-lg-145t margin-sm-100t">
                        <Row wrap={false} justify={"space-around"} align={"middle"}
                            className="container padd-only-xs">
                            <div></div>
                            <div className="w-100"><h1 className="title d-flex justify-content-center"
                                                       style={{marginBottom: "10px"}}>ÚSPĚŠNÉ REALIZACE V RÁMCI CELÉ
                                EVROPY</h1>
                                <div className=" d-flex justify-content-center" style={{marginBottom: "20px"}}>
                                    <div className="text-center">
                                        <text><p></p><p> Co náš zákazník navrhne, to dodáme na stavbu rychle a včas.</p>
                                            <p>Nabízíme kompletní stavební systém SIP včetně všech potřebných materiálů
                                                pro dokončení hrubé stavby. SIP panely zhotovujeme na míru dle
                                                projektových dokumentací ve výrobní hale. Našim zákazníkům tak
                                                nevznikají dodatečné práce na staveništi, šetří se čas a peníze.
                                                Zajišťujeme komfortní řízení stavby bez zbytečného přerušování kvůli
                                                subdodávkám.</p><p></p></text>
                                    </div>
                                </div>
                            </div>
                            {user && (
                                <Row justify={"end"} align={'middle'}>
                                    <Button shape={"circle"} icon={<PlusOutlined/>} onClick={() => setEdit(true)}/>
                                    {edit && <SipRealizationForm house={!(typeof edit === 'boolean') ? edit : undefined}
                                                                 onFinish={() => setEdit(false)}/>}
                                </Row>
                            )}
                        </Row>
                        <div className="col-sm-12 mb-4">
                            <div className="vc_column-inner">
                                <div data-unique-key="14ad5b356d917aa464e6341724c17a46" className="js-load-more"
                                     data-start-page="1" data-max-page="1" data-next-link="">
                                    <div className="gallery-grid">
                                        <Sorter onSortEnd={onSortEnd} items={sortedHouses}
                                                renderItem={(house, props, handleProps) => <div
                                                    className="gallery-grid-item" {...props}>
                                                    <SipRealizationCard
                                                        house={house} onEdit={setEdit} handleProps={handleProps}/>
                                                </div>}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SipRealizations;
