import React, {useState} from 'react';
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import FileService from "../service/app/FileService";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../interface/redux/IAppState";
import {AppDispatch} from "../redux/store";
import RecordsOrderService from "../service/app/RecordsOrderService";
import {ISipHouse} from "../interface/data/ISipHouse";
import {updateSetup} from "../redux/slice/Setup";
import Sorter from "../util/Sorter";
import SipHouseCard from "./card/SipHouseCard";

function Sip(props: any) {

    const ORDER = 'sip-house-home'
    const user = useSelector((state: IAppState) => state.setup.user);
    const houses = useSelector((state: IAppState) => state.setup.sipHouses).filter(h => ((h.isPublic !== false) || user) && h.onHomepage);
    const orderList = useSelector((state: IAppState) => state.setup.recordsOrder);
    const dispatch = useDispatch<AppDispatch>();


    const sortedHouses = RecordsOrderService.sort(ORDER, orderList, houses);

    const [edit, setEdit] = useState<boolean | ISipHouse>(false);

    const onSortEnd = (sorted: ISipHouse[]) => {
        dispatch(updateSetup({recordsOrder: RecordsOrderService.sortUpdate(ORDER, orderList, sorted)}));
        RecordsOrderService.create({id: ORDER, order: sorted.map(h => h.id)}).then(r => {
        })
    }

    return (
        <div>
            <div>
                <div className="row m-0">
                    <div className="col-xs-12 no-padd">
                        <div className="position-relative h-100">
                            <div className="container-fluid top-banner no-padd big fullheight light"
                                 style={{background: 'url("/img/sip/img.png") center center / cover'}}><span
                                className="overlay"></span>
                                <div className="contentmdum">
                                    <div className="prague-svg-animation-text"></div>
                                    <div className="subtitle position-relative d-inline-block"
                                         style={{fontSize: '30px'}}> DOMY SIP
                                    </div>
                                    <h1 className="title display-linebreak position-relative"
                                        style={{width: 'max-content'}}> Inovativní stavební </h1></div>
                                <div className="top-banner-cursor"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container padd-only-xs margin-lg-55t">
                    <div className="row">
                        <div className="col-xs-12 padd-only-xs">
                            <div className="container no-padd">
                                <Row className="row-fluid margin-lg-35t" gutter={[15, 15]}>
                                    <Col xs={24} sm={12}>
                                        <div className="padd-only">
                                            <div className="left dark"><h2 className="title">SIP Panely</h2>
                                                <div className="content padding-xs-40b position-relative">
                                                    <div><p> Specializujeme se na stavební systém SIP (Structured
                                                        Insulated Panel), který se celosvětově používá již 40 let (v
                                                        Kanadě 80% a v USA 65% všech rodinných domů je postaveno touto
                                                        jedinečnou technologií). </p> <p> Domy ze SIP panelů jsou
                                                        energeticky úsporné a vyžadují minimální náklady na jejich
                                                        provoz a údržbu.. </p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className="no-padd-inner">
                                            <div className="ml-4">
                                                <div className="sip-image position-relative"><img
                                                    src="/img/sip/about.png"
                                                    className="prague-team-img border-radius-10 o-cover" alt="about"/>
                                                    <div className="tag">Složení SIP panelů</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin-lg-70t">
                    <div className="col-xs-12 no-padd">
                        <div className="position-relative h-100"><img src="/img/sip/advantages.png"
                                                                      className="container-fluid no-padd light"
                                                                      alt="info"/>
                        </div>
                    </div>
                </div>
                <div className="container padd-only-xs margin-xs-20t margin-md-0t">
                    <div className="row">
                        <div className="col-xs-12 padd-only-xs">
                            <div className="container no-padd">
                                <div className="row-fluid margin-lg-70t"><h2
                                    className="title text-center margin-lg-45b">Naše
                                    Projekty</h2>
                                    <div className="column-inner">
                                        <div className="wrapper">
                                            <div data-unique-key="d30797fabd788147235e48099dfaacc0"
                                                 className="js-load-more"
                                                 data-start-page="1" data-max-page="4">
                                                <Row justify={"space-around"} className="w-100 m-0" gutter={[32, 32]}>
                                                    <Sorter onSortEnd={onSortEnd} items={sortedHouses}
                                                            renderItem={(house, props, handleProps) => <Col xs={24}
                                                                                                            sm={12}
                                                                                                            lg={8}
                                                                                                            {...{...props, className: props.className + " d-flex"}}>
                                                                <SipHouseCard
                                                                    house={house} onEdit={setEdit}
                                                                    handleProps={handleProps}/></Col>}/>
                                                </Row>
                                                <div className="row m-0">
                                                    <div className="col-sm-12 text-center"><Link to="/sip/katalog">
                                                        <button className="a-btn-2 creative viva-button anima"><span
                                                            className="a-btn-line load"></span>Zobrazit všechny
                                                        </button>
                                                    </Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container padd-only-xs margin-xs-20t margin-md-0t margin-lg-90b">
                        <div className="row">
                            <div className="col-xs-12 padd-only-xs">
                                <div className="container no-padd">
                                    <div className="row-fluid margin-lg-70t"><h2
                                        className="title text-center margin-lg-45b">Naše Služby</h2>
                                        <Row style={{marginLeft: '-20px', marginRight: '-20px'}}>
                                            <Col xs={24} sm={12} lg={8}
                                                 style={{paddingLeft: '20px', paddingRight: '20px', flex: '1 1 0%'}}>
                                                <div id="spokojenost"
                                                     className="portfolio-item-wrapp prague_filter_class h-100">
                                                    <div className="portfolio-item h-100">
                                                        <div className="prague-services-wrapper h-100"><img
                                                            className="mb-3"
                                                            src="/img/sip/icon/icon-1.png"
                                                            alt="icon"
                                                            style={{height: '50px'}}/>
                                                            <h3 className="services-item-title">Konstrukce domu</h3>
                                                            <div className="services-item-description">
                                                                <div className="position-relative w-max-content">
                                                                    <p> Konstrukce domu se skládá z nosných stěn ze SIP
                                                                        panelů. Vnitřní příčky konstrukce s
                                                                        protihlukovou
                                                                        izolací Záruka na konstrukční systém 30
                                                                        let! </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} lg={8}
                                                 style={{paddingLeft: '20px', paddingRight: '20px', flex: '1 1 0%'}}>
                                                <div id="spokojenost"
                                                     className="portfolio-item-wrapp prague_filter_class h-100">
                                                    <div className="portfolio-item h-100">
                                                        <div className="prague-services-wrapper h-100"><img
                                                            className="mb-3"
                                                            src="/img/sip/icon/icon-2.png"
                                                            alt="icon"
                                                            style={{height: '50px'}}/>
                                                            <h3 className="services-item-title">Fasáda</h3>
                                                            <div className="services-item-description">
                                                                <div className="position-relative w-max-content">
                                                                    <p> Obvodový zateplovací systém Omítka vnější
                                                                        silikonová
                                                                        + sokl - silikonová omítka jednobarevná do výšky
                                                                        40
                                                                        cm </p></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} lg={8}
                                                 style={{paddingLeft: '20px', paddingRight: '20px', flex: '1 1 0%'}}>
                                                <div id="spokojenost"
                                                     className="portfolio-item-wrapp prague_filter_class h-100">
                                                    <div className="portfolio-item h-100">
                                                        <div className="prague-services-wrapper">
                                                            <img className="mb-3"
                                                                 src="/img/sip/icon/icon-3.png"
                                                                 alt="icon"
                                                                 style={{height: '50px'}}/>
                                                            <h3 className="services-item-title">INTERIÉR</h3>
                                                            <div className="services-item-description">
                                                                <div className="position-relative w-max-content">
                                                                    <p> Vnitřní
                                                                        kanalizace + vodovod Hrubá instalace
                                                                        ELI <br/> Izolace
                                                                        podlahy <br/> Potěry <br/> Izolace
                                                                        stropu <br/> Podhledy <br/> Topení </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sip;
