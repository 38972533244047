import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISetup} from "../../interface/redux/ISetup";

const initialState: ISetup = {
    modularHouses: [],
    sipHouses: [],
    recordsOrder: [],
    realizations: [],
    sipRealizations: [],
    editableImages: [],
    editableTexts: [],
    user: undefined,
    loading: true
};

const setupSlice = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        saveSetup(state, action: PayloadAction<ISetup>) {
            state.modularHouses = action.payload.modularHouses
            state.sipHouses = action.payload.sipHouses
            state.recordsOrder = action.payload.recordsOrder
            state.editableImages = action.payload.editableImages
            state.realizations = action.payload.realizations
            state.sipRealizations = action.payload.sipRealizations
            state.editableTexts = action.payload.editableTexts
            state.user = action.payload.user
            state.loading = false
        },
        updateSetup(state, action: PayloadAction<Partial<ISetup>>) {

            Object.keys(action.payload).forEach((key) => {
                state[key] = action.payload[key];
            });
        }
    },
});

export const { saveSetup } = setupSlice.actions;
export const { updateSetup } = setupSlice.actions;

export default setupSlice.reducer;


