import React from 'react';
import EditableText from "../components/EditableText";
import EditableImage from "../components/EditableImage";

function MobileHouses() {

    return (
        <div>
            <div>
                <div>
                    <div className="row m-0">
                        <div className="col-xs-12 no-padd">
                            <div className="position-relative ">
                                <EditableImage code={'mobile-houses.cover'} buttonPosition={{includeHeader: true}}
                                               defaultUrl={'/img/mdum/6.jpg'}
                                               render={(url) => (
                                                   <div
                                                       className="container-fluid top-banner no-padd big fullheight light"
                                                       style={{
                                                           backgroundImage:  'url("' + url + '")',
                                                           backgroundPosition: 'center center',
                                                           backgroundSize: 'cover',
                                                           backgroundRepeat: 'no-repeat',
                                                           minHeight: '100vh'
                                                       }}><span className="overlay"></span>
                                                       <div className="contentmdum">
                                                           <div className="prague-svg-animation-text"></div>

                                                           <div className="subtitle position-relative d-inline-block"
                                                                style={{fontSize: '30px'}}>
                                                               <EditableText type={'plain'}
                                                                             code={'mobile-houses.cover.small.title'}>
                                                                   Mobilní domy
                                                               </EditableText>
                                                           </div>
                                                           <h1 className="title display-linebreak position-relative"
                                                               style={{width: 'max-content'}}>
                                                               <EditableText type={'text'}
                                                                             code={'mobile-houses.cover.big.title'}>
                                                                   Dynamický a cenově dostupný luxus
                                                               </EditableText></h1>
                                                       </div>
                                                       <div className="top-banner-cursor"></div>
                                                   </div>
                                               )}/>

                            </div>
                        </div>
                    </div>
                    <div className="container no-padd">
                        <div className="row-fluid margin-lg-70t">
                            <div
                                className="column col-sm-10 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-1 col-xs-12 margin-lg-65t margin-sm-0t no-padd">
                                <div className="no-padd-inner">
                                    <div className="prague-counter multi_item no-figure"><img src="/img/mdum/mbarak.jpg"
                                                                                              className="prague-team-img border-radius-10 h-380 o-cover"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="column col-sm-12 col-lg-offset-1 col-lg-5 col-md-offset-0 col-md-6 col-xs-12 margin-sm-20t padd-only-xs">
                                <div className="padd-only">
                                    <div className="heading left dark"><h2 className="title position-relative">
                                        <EditableText type={'wysiwyg'} code={'mobile-houses.tommy.title'}>
                                            Mobilní dům<br/>model TOMMY
                                        </EditableText>
                                    </h2>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText type={'wysiwyg'} wrap={true}
                                                          code={'mobile-houses.tommy.description'}>
                                                <p><strong>Po pečlivém průzkumu dovážíme ty nejkvalitnější mobilní
                                                    domy, které splňují nejpřísnější parametry.</strong></p><p>Vybavení
                                                domu
                                                jsme připraveni přízpůsobit potřebám zákazníka. Vnější rozměry domu do
                                                4m šířky, venkovní osvětlení, tvar-styl- barvu-obložení fasády, vnitřní
                                                vybavení spotřebiči, zabudovanou televizí, klimatizací, podlahovým
                                                vytápěním, počty místností a rozměry, vybavení kuchyně-koupelny-pokojů,
                                                vestavěné skříně, inovativní moderní technologie, alarm, kamery,
                                                terasa.</p>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="margin-lg-20t margin-sm-30t">
                        <div className="container">
                            <div className="row">
                                <div className="column colum-container col-sm-12 no-padd margin-lg-20b">
                                    <div className="heading left dark">
                                        <div className="subtitle w-max-content position-relative">
                                            <EditableText type={"plain"} code={'mobile-houses.tommy.interior.title'}>
                                                TOMMY
                                            </EditableText>
                                        </div>
                                        <h2 className="title position-relative w-max-content">
                                            <EditableText type={"plain"} code={'mobile-houses.tommy.interior'}>
                                                Interiér
                                            </EditableText>
                                        </h2>
                                    </div>
                                </div>
                                <div className="no-padd-left no-padd-right margin-lg-20t">
                                    <div className="wrapper">
                                        <div data-unique-key="139714cb2b9c35c987d2544328454258"
                                             className="js-load-more">
                                            <div
                                                className="row prague_list prague_count_col1 prague_gap_col10 no-footer-content no-figure prague-load-wrapper"
                                                data-columns="prague_count_col1" data-gap="prague_gap_col10">
                                                <div className="project-list-item">
                                                    <div className="project-list-outer">
                                                        <div className="project-list-wrapper">
                                                            <div className="project-list-img"><img
                                                                src="/img/mdum/loznice.jpg" className="h-100 o-cover"
                                                                alt="loznice"/></div>
                                                            <div className="project-list-content">
                                                                <div className="project-list-category">TOMMY</div>
                                                                <h3 className="project-list-title"><a>Ložnice</a></h3>
                                                                <div className="project-list-excerpt">
                                                                    <EditableText type={"wysiwyg"} wrap={true}
                                                                                  code={'mobile-houses.tommy.bedroom.description'}>
                                                                        <ul>
                                                                            <li><strong>El. regulovaný
                                                                                přímotop</strong></li>
                                                                            <li><strong>Gavitační větrací
                                                                                mřížka</strong></li>
                                                                            <li><strong>Balkónové okno - </strong>bílé
                                                                                200x70cm
                                                                            </li>
                                                                            <li><strong>Venkovní dveře - </strong>bílé
                                                                                70cm
                                                                            </li>
                                                                            <li><strong>Strop obložení - </strong>borovice,
                                                                                smrek
                                                                            </li>
                                                                            <li><strong>Stěna - </strong>bílý PVC
                                                                                panel
                                                                            </li>
                                                                            <li><strong>Podlaha - </strong>lamino
                                                                                panel
                                                                            </li>
                                                                        </ul>
                                                                    </EditableText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-list-item column_paralax">
                                                    <div className="project-list-outer">
                                                        <div className="project-list-wrapper">
                                                            <div className="project-list-img"><img
                                                                src="/img/mdum/obyvacipokoj.jpg"
                                                                className="h-100 o-cover" alt="obyvacipokoj"/></div>
                                                            <div className="project-list-content">
                                                                <div className="project-list-category">TOMMY</div>
                                                                <h3 className="project-list-title text-left"><a>Obývací
                                                                    pokoj</a></h3>
                                                                <div className="project-list-excerpt text-left" style={{
                                                                    fontSize: '20px',
                                                                    marginLeft: '20px'
                                                                }}>
                                                                    <EditableText type={"wysiwyg"} wrap={true}
                                                                                  code={'mobile-houses.tommy.living-room.description'}>
                                                                        <ul>
                                                                            <li><strong>Posuvné balkonové dveře
                                                                                - </strong> 200x200cm
                                                                            </li>
                                                                            <li><strong>Stěna - </strong>bílý PVC
                                                                                panel + ľubovoľný výber
                                                                            </li>
                                                                            <li><strong>Strop obložení - </strong>borovice,
                                                                                smrek
                                                                            </li>
                                                                            <li><strong>Podlaha - </strong>lamino
                                                                                panel
                                                                            </li>
                                                                            <li><strong>Okno - </strong>bílé
                                                                                200x70cm
                                                                            </li>
                                                                            <li><strong>Osvětlení - </strong>zásuvky
                                                                            </li>
                                                                            <li><strong>Boční stěna - </strong>3,3m
                                                                            </li>
                                                                            <li><strong>El. regulovaný
                                                                                přímotop</strong></li>
                                                                            <li><strong>Gavitační větrací
                                                                                mřížka</strong></li>
                                                                            <li><strong>TV - Data - Alarm</strong>
                                                                            </li>
                                                                        </ul>
                                                                    </EditableText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-list-item">
                                                    <div className="project-list-outer">
                                                        <div className="project-list-wrapper">
                                                            <div className="project-list-img"><img
                                                                src="/img/mdum/pokoj.jpg" className="h-100 o-cover"
                                                                alt="yabroudi-villa image"/></div>
                                                            <div className="project-list-content">
                                                                <div className="project-list-category">TOMMY</div>
                                                                <h3 className="project-list-title"><a>Pokoj</a></h3>
                                                                <div className="project-list-excerpt">
                                                                    <EditableText type={"wysiwyg"} wrap={true}
                                                                                  code={'mobile-houses.tommy.room.description'}>
                                                                        <ul>
                                                                            <li><strong>El. regulovaný
                                                                                přímotop</strong></li>
                                                                            <li><strong>Gavitační větrací
                                                                                mřížka</strong></li>
                                                                            <li><strong>Osvětlení</strong></li>
                                                                            <li><strong>Zásuvky</strong></li>
                                                                            <li><strong>Příprava </strong> pro
                                                                                klimatizaci
                                                                            </li>
                                                                        </ul>
                                                                    </EditableText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-list-item">
                                                    <div className="project-list-outer">
                                                        <div className="project-list-wrapper">
                                                            <div className="project-list-img"><img
                                                                src="/img/mdum/kuchyn.jpg" className="h-100 o-cover"
                                                                alt="cultural-complex-centre image"/></div>
                                                            <div className="project-list-content">
                                                                <div className="project-list-category">TOMMY</div>
                                                                <h3 className="project-list-title text-left">
                                                                    <a>Kuchyň</a></h3>
                                                                <div className="project-list-excerpt text-left" style={{
                                                                    fontSize: '20px',
                                                                    marginLeft: '20px'
                                                                }}>
                                                                    <EditableText type={"wysiwyg"} wrap={true}
                                                                                  code={'mobile-houses.tommy.kitchen.description'}>
                                                                        <ul>
                                                                            <li><strong>LED osvětlení </strong> pod
                                                                                horními skříňkami
                                                                            </li>
                                                                            <li><strong>Ventilator </strong> s
                                                                                čidlem vlhkosti
                                                                            </li>
                                                                            <li><strong>Kuchyňský nábytek
                                                                                - </strong>Bílý
                                                                            </li>
                                                                            <li><strong>Pracovní deska - </strong>Hnědá
                                                                            </li>
                                                                            <li><strong>Černý dřez</strong></li>
                                                                            <li><strong>Osvětlení</strong></li>
                                                                            <li><strong>Zásuvky</strong></li>
                                                                        </ul>
                                                                    </EditableText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-list-item column_paralax">
                                                    <div className="project-list-outer">
                                                        <div className="project-list-wrapper">
                                                            <div className="project-list-img"><img
                                                                src="/img/mdum/koupf.jpeg"
                                                                className="h-100 o-cover w-100"
                                                                alt="dalbourne-villa image"/></div>
                                                            <div className="project-list-content">
                                                                <div className="project-list-category">TOMMY</div>
                                                                <h3 className="project-list-title"><a>Koupelna</a></h3>
                                                                <div className="project-list-excerpt">
                                                                    <EditableText type={"wysiwyg"} wrap={true}
                                                                                  code={'mobile-houses.tommy.bathroom.description'}>
                                                                        <ul>
                                                                            <li><strong>Okno - </strong>PVC 450 x
                                                                                700 mm, výklopné / otočné
                                                                            </li>
                                                                            <li><strong>Sprchový kout - </strong>pevní
                                                                                zástěna, 80x80 cm
                                                                            </li>
                                                                            <li><strong>Stěny - </strong>PVC stěnový
                                                                                panel jakékoli barvy
                                                                            </li>
                                                                            <li><strong>Strop - </strong>Bílý
                                                                                nástěnný panel z PVC
                                                                            </li>
                                                                            <li><strong>Vnitřní dveře - </strong>bílé
                                                                                70cm
                                                                            </li>
                                                                            <li><strong>Podlaha - </strong>vinylový
                                                                                panel
                                                                            </li>
                                                                            <li><strong>Bílé žebříkové elektrické
                                                                                topení</strong></li>
                                                                            <li><strong>Bílá skříňka s umyvadlem 50
                                                                                cm</strong></li>
                                                                            <li><strong>Elektrický ohřívač vody,
                                                                                bojler</strong></li>
                                                                            <li><strong>Záchodová mísa</strong></li>
                                                                            <li><strong>Zrcadlo</strong></li>
                                                                        </ul>
                                                                    </EditableText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="no-padd col-sm-12" style={{marginTop: '50px'}}>
                        <div className="column-inner">
                            <div className="prague-shortcode-parent position-relative">
                                <EditableImage code={'mobile-houses.technical'}
                                               defaultUrl={'/img/wood.jpg'}
                                               render={(url) => (
                                                   <div className="prague-shortcode-parent-img"
                                                        style={{backgroundImage: 'url("' + url + '")'}}><span
                                                       className="overlay"></span>
                                                   </div>
                                               )}/>
                                <div className="prague-shortcode-content-wrapp no-padd-md no-padd-xs">
                                    <div className="prague-shortcode-heading light left">
                                        <div className="parent-subtitle">PARAMETRY</div>
                                        <h2 className="parent-title position-relative w-max-content">
                                            <EditableText type={"plain"} code={'mobile-houses.tech-parameters.title'}>
                                                Technické parametry
                                            </EditableText></h2></div>
                                    <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                                         data-start-page="1" data-max-page="2">
                                        <div
                                            className="d-flex flex prague_services prague_count_col3 prague_gap_col15 no-footer-content prague-load-wrapper"
                                            data-columns="prague_count_col3" data-gap="prague_gap_col15">
                                            <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '350px'}}>
                                                        <h3 className="services-item-title position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.tech-parameters.1.title'}>
                                                                Stěny, stropy a podlahy
                                                            </EditableText></h3>
                                                        <div className="services-item-description">
                                                            <EditableText type={"wysiwyg"} wrap={true}
                                                                          code={'mobile-houses.tech-parameters.1.description'}>
                                                                <p> Koupelnové stěny
                                                                    / strop PVC panely bíle barvy, koupelnové voděodolné
                                                                    vinylové podlahové panely (barva po dohodě), podlaha
                                                                    obývacího pokoje AC4 / AC5 - podlahový panel, hnědá
                                                                    barva </p>
                                                            </EditableText>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="dizajn" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '350px'}}>
                                                        <h3 className="services-item-title position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.tech-parameters.2.title'}>
                                                                Konstrukce
                                                            </EditableText></h3>
                                                        <div className="services-item-description">
                                                            <EditableText type={"wysiwyg"} wrap={true}
                                                                          code={'mobile-houses.tech-parameters.2.description'}>
                                                                <p> Dvě nápravy /
                                                                    čtyři kola, podvozek s antikorózním nátěrem, KVH
                                                                    hranoly 180
                                                                    x 40 mm, izolace 150 mm, 1 x záklop OSB 18 mm, 1 x
                                                                    záklop
                                                                    OSB 8-10 mm, parotěsná fólie, hydroizolační
                                                                    fólie </p>
                                                            </EditableText></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="setrenie" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '350px'}}>
                                                        <h3 className="services-item-title position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.tech-parameters.3.title'}>
                                                                Rozvody
                                                            </EditableText></h3>
                                                        <div className="services-item-description">
                                                            <EditableText type={"wysiwyg"} wrap={true}
                                                                          code={'mobile-houses.tech-parameters.3.description'}>
                                                                <p> Rozvody vody a
                                                                    odpadů s výpustí mimo mobilní dům, příprava a
                                                                    instalace pro
                                                                    alarm / kamery, kompletní elektroinstalace,
                                                                    ventilátory v
                                                                    koupelně a kuchyni (tiché a s čidlem vlhkosti), dvě
                                                                    externí
                                                                    zásuvky
                                                                </p>
                                                            </EditableText>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="kvalita" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '350px'}}>
                                                        <h3 className="services-item-title position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.tech-parameters.4.title'}>
                                                                Fasáda
                                                            </EditableText></h3>
                                                        <div className="services-item-description">
                                                            <EditableText type={"wysiwyg"} wrap={true}
                                                                          code={'mobile-houses.tech-parameters.4.description'}>
                                                                <p> Fasáda z
                                                                    impregnovaného dřeva a barevných plechů (barvy po
                                                                    dohode),
                                                                    střecha sedlová, krytá trapézovým plechem, plastové
                                                                    žlaby a
                                                                    odtoky </p>
                                                            </EditableText></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '350px'}}>
                                                        <h3 className="services-item-title position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.tech-parameters.5.title'}>
                                                                Okna
                                                            </EditableText></h3>
                                                        <div className="services-item-description">
                                                            <EditableText type={"wysiwyg"} wrap={true}
                                                                          code={'mobile-houses.tech-parameters.5.description'}>
                                                                <p> Okna uvnitř bílá,
                                                                    venku antracitová, 5 komorový systém pro zvýšené
                                                                    nároky na
                                                                    tepelnou izolaci, koeficient Uf = 1,3 W/(m²K) </p>
                                                            </EditableText></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/img/mdum/view.jpg" style={{
                        borderRadius: '30px',
                        margin: 'auto',
                        display: 'flex',
                        maxWidth: '90%',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                    }} alt="view"/>
                    <div className="no-padd">
                        <div className="column-inner">
                            <div className="prague-shortcode-parent" style={{paddingBottom: '30px'}}>
                                <div className="prague-shortcode-content-wrapp no-padd-md no-padd-xs">
                                    <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                                         data-start-page="1" data-max-page="2"><h2 className="text-center">Ceník</h2>
                                        <div
                                            className="d-flex prague_services prague_count_col3 prague_gap_col15 no-footer-content prague-load-wrapper"
                                            data-columns="prague_count_col3" data-gap="prague_gap_col15">
                                            <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '170px'}}>
                                                        <h3 className="services-item-title position-relative"
                                                            style={{color: 'rgb(160, 117, 59)'}}>
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.price.small'}>
                                                                1 290 000 Kč
                                                            </EditableText></h3>
                                                        <div className="services-item-description"><h4
                                                            className="position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.area.small'}>
                                                                10m x 3,5m - 35m2
                                                            </EditableText></h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="kvalita" className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '170px'}}>
                                                        <h3 className="services-item-title position-relative"
                                                            style={{color: 'rgb(160, 117, 59)'}}>
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.price.medium'}>
                                                                1 490 000 Kč
                                                            </EditableText></h3>
                                                        <div className="services-item-description"><h4
                                                            className="position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.area.medium'}>
                                                                12m x 4m - 48m²
                                                            </EditableText></h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper" style={{height: '170px'}}>
                                                        <h3 className="services-item-title position-relative"
                                                            style={{color: 'rgb(160, 117, 59)'}}>
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.price.big'}>
                                                                1 690 000 Kč
                                                            </EditableText></h3>
                                                        <div className="services-item-description"><h4
                                                            className="position-relative">
                                                            <EditableText type={"plain"}
                                                                          code={'mobile-houses.area.big'}>
                                                                13,5m x 4,2m - 57m²
                                                            </EditableText></h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileHouses;
