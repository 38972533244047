import React, {useRef, useState} from 'react';
import {Button, Col, Divider, Form, FormInstance, Modal, Row, UploadFile} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {EditOutlined} from "@ant-design/icons";
import {updateSetup} from "../redux/slice/Setup";
import {IAppState} from "../interface/redux/IAppState";
import {AppDispatch} from "../redux/store";
import Uploader from "../util/Uploader";
import FileService from "../service/app/FileService";
import {IFile} from "../interface/data/IFile";
import EditableImageService from "../service/app/EditableImageService";

interface IProps {
    code: string
    defaultUrl: string
    render: (url: string) => React.ReactNode | string | React.JSX.Element
    buttonPosition?: {top?: number; right?: number, includeHeader?: boolean}
}


const EditableImage: React.FC<IProps> = ({code, defaultUrl, render, buttonPosition}) => {

    const formRef = useRef<FormInstance>()
    const [saving, setSaving] = useState(false)
    const [editing, setEditing] = useState(false)

    const dispatch = useDispatch<AppDispatch>();
    const images = useSelector((state: IAppState) => state.setup.editableImages);
    const user = useSelector((state: IAppState) => state.setup.user);

    const image = images.find(t => t.id === code)

    const submit = async () => {
        formRef.current?.validateFields().then(async (values: { upload: (UploadFile & IFile)[] }) => {
            setSaving(true)
            const newImages = await FileService.uploadAndSync(values.upload, image ? [image?.file] : []);
            const result = await EditableImageService.create({
                id: code,
                file: newImages[0] || null
            })
            setSaving(false)
            setEditing(false)
            let updatedImages = [...images]
            const index = updatedImages.findIndex(h => h.id === result.id)
            if (index > -1) {
                updatedImages[index] = result
            } else {
                updatedImages.push(result)
            }
            dispatch(updateSetup({editableImages: updatedImages}));
        })
    }

    function getEditButton() {
        return user && <div className="position-absolute" style={{top: (buttonPosition?.top || 25) + (buttonPosition?.includeHeader ? 88 : 0), right: buttonPosition?.right || 25, zIndex: 250}}>
            <Button icon={<EditOutlined/>} shape={'circle'} onClick={() => setEditing(true)}/>
        </div>;
    }

    return (
        <>
            {render(image?.file ? FileService.buildSrcUrl(image?.file.uid) : defaultUrl)}
            {getEditButton()}

            <Modal title={'Upravit'} destroyOnClose={true} open={editing} width={800} onCancel={() => setEditing(false)}
                   styles={{
                       body: {
                           maxHeight: '60vh',
                           overflow: 'hidden auto'
                       }
                   }} footer={<div>
                <Divider className={'m-2'}/>
                <Row key={'footer'} justify={"end"}>
                    <Col>
                        <Button onClick={() => setEditing(false)} className={'mr-2'}>Zrušiť</Button>
                        <Button type={'primary'} onClick={submit} loading={saving}>Uložiť</Button>
                    </Col>
                </Row></div>}>
                <Form ref={formRef as any} layout={'vertical'} initialValues={{upload: image?.file ? [image?.file] : []}}>
                    <Form.Item name={'upload'} label={'Obrazek'}
                               /*rules={[{required: true, message: 'Pole je povinné'}]}*/>
                        <Uploader multiple={false} accept={['image/*']}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
        ;
}

export default EditableImage;
