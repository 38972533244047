import Fetcher, {IFetcherService, IFetcherSubService} from "../Fetcher";
import {IRecordsOrder} from "../../interface/data/IRecordsOrder";
import {updateSetup} from "../../redux/slice/Setup";

const NAME = 'records-order';

interface IProps extends IFetcherSubService<IRecordsOrder>{
    sort: <I extends {id: string}>(name: string, orderList: IRecordsOrder[], records: I[]) => I[];
    sortUpdate: <I extends {id: string}>(name: string, orderList: IRecordsOrder[], records: I[]) => IRecordsOrder[];
}

const RecordsOrderService : IProps = {
    create: (item) => {
        return Fetcher.create(item, NAME)
    },

    update:  (id, item) => {
        return Fetcher.update(id, item, NAME)
    },

    list: () => {
        return Fetcher.list(NAME)
    },

    get: (id) => {
        return Fetcher.get(id, NAME)
    },

    delete: (id) => {
        return Fetcher.delete(id, NAME)
    },
    sort: (name, orderList, records) => {
        const recordsOrder = orderList.find(o => o.id === name)
        let sortedRecords: (typeof records) = []
        if (recordsOrder) {
            recordsOrder.order.forEach(order => {
                const house = records.find(o => o.id === order)
                if (house) {
                    sortedRecords.push(house)
                }
            })
            records.forEach(r => {
                if (!sortedRecords.find(o => o.id === r.id)) {
                    sortedRecords.push(r)
                }
            })
            return sortedRecords;
        } else {
            return records
        }
    },
    sortUpdate: (name, orderList, sorted) => {
        const r = {id: name, order: sorted.map(h => h.id)}
        let updatedOrderList = [...orderList]
        const index = updatedOrderList.findIndex(h => h.id === name)
        if (index > -1) {
            updatedOrderList[index] = r
        } else {
            updatedOrderList.push(r)
        }
        return updatedOrderList
    }
};

export default RecordsOrderService;
