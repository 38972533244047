import React, {useRef, useState} from 'react';
import {Alert, Button, Col, Divider, Form, FormInstance, Input, Modal, Row, Tooltip, UploadFile} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../interface/redux/IAppState";
import FileService from "../../service/app/FileService";
import {ISipRealization} from "../../interface/data/ISipRealization";
import FileUploader from "../../util/Uploader";
import {DragOutlined, EyeOutlined} from "@ant-design/icons";
import {updateSetup} from "../../redux/slice/Setup";
import {AppDispatch} from "../../redux/store";
import SipRealizationService from "../../service/app/SipRealizationService";
import {IFile} from "../../interface/data/IFile";

interface IProps {
    house?: ISipRealization
    onFinish: (house?: ISipRealization) => void
}

const SipRealizationForm: React.FC<IProps> = ({house, onFinish}) => {

    const formRef = useRef<FormInstance>()
    const [saving, setSaving] = useState(false)
    const [isPublic, setIsPublic] = useState<boolean>(house?.isPublic === undefined ? true : house?.isPublic)

    const dispatch = useDispatch<AppDispatch>();
    const houses = useSelector((state: IAppState) => state.setup.sipRealizations);

    const submit = async () => {
        formRef.current?.validateFields().then(async (values: {images:  (UploadFile & IFile)[], [param: string]: any}) => {
            setSaving(true)
            const newImages = await FileService.uploadAndSync(values.images, house?.images || []);

            try {
                const result = await SipRealizationService.create({
                    id: house?.id,
                    ...values,
                    images: newImages,
                    isPublic: isPublic
                })
                setSaving(false)
                let updatedHouses = [...houses]
                const index = updatedHouses.findIndex(h => h.id === result.id)
                if (index > -1){
                    updatedHouses[index] = result
                } else {
                    updatedHouses.push(result)
                }
                dispatch(updateSetup({sipRealizations: updatedHouses}));
                onFinish()
            } catch {
                // setSaving(false)
                // return
            }
        })
    }

    return (
        <Modal title={'Upravit'} open={true} width={800} onCancel={() => onFinish()} styles={{
            body: {
                maxHeight: '60vh',
                overflow: 'hidden auto'
            }
        }} footer={<div>
            <Divider className={'m-2'}/>
            <Row key={'footer'} justify={"space-between"}>
            <Col>
                <Tooltip title={isPublic ? 'Skrýt tento dům' : 'Zveřejnit tento dům'}>
                    <Button type={isPublic ? 'primary' : 'default'} icon={<EyeOutlined/>} className={'mr-2'}
                            onClick={() => setIsPublic(!isPublic)}></Button>
                </Tooltip>
            </Col>
            <Col>
                <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                <Button type={'primary'} onClick={submit} loading={saving}>Uložiť</Button>
            </Col>
        </Row></div>}>
            <Form ref={formRef as any} layout={'vertical'} initialValues={{...house}}>
                <Form.Item name={'location'} label={'Lokace'} rules={[{required: true, message: 'Pole je povinné'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'images'} label={'Obrázky'}>
                    <FileUploader accept={['image/*']}/>
                </Form.Item>
                <Alert className={'py-2'} type={"info"}
                       description={<div className="f-small">První obrázek je vždy profilový obrázek <br/>Řadit
                           můžete
                           přetažením pomocí tlačítka s ikonou <DragOutlined/>
                </div>}/>
            </Form>
        </Modal>
    )
        ;
}

export default SipRealizationForm;
