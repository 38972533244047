import React, {ReactNode, useCallback, useState} from 'react';
import {Button, Image as AntdImage, Upload, UploadFile} from 'antd';
import {DragOutlined, PlusOutlined} from '@ant-design/icons';
import {RcFile} from "antd/es/upload";
import FileService from "../service/app/FileService";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import SortableItem from './sorter/SortableItem';

interface IProps {
    value?: UploadFile[];
    onChange?: (fileList: UploadFile[]) => void;
    accept?: string[]
    multiple?: boolean
    previewIcon?: boolean
}

let renders: { [key: string]: React.ReactNode } = {}
const addRender = (key: string, node: ReactNode) => {
    renders = {...renders, [key]: node}
}

const FileUploader: React.FC<IProps> = ({value = [], onChange, accept, multiple = true, previewIcon = true}) => {
        const [fileList, setFileList] = useState<UploadFile[]>(value.map(v => ({
            ...v,
            thumbUrl: previewIcon ? FileService.buildSrcUrl(v.uid) : undefined,
            url: previewIcon ? FileService.buildSrcUrl(v.uid) : undefined
        })));
        const [preview, setPreview] = useState<undefined | string>();

        const handleRemove = (file: UploadFile) => {
            const newFileList = fileList.filter(item => item.uid !== file.uid);
            setFileList(newFileList);
            if (onChange) {
                onChange(newFileList);
            }
        };

        const convertRcFileToUploadFile = (file: RcFile): UploadFile => {
            return {uid: file.uid, name: file.name, status: 'done', originFileObj: file, url: URL.createObjectURL(file)};
        };

        const getBase64 = (file: Blob) => {
            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = error => reject(error);
            });
        }

        const handleChange = async (info: { fileList: UploadFile[] }) => {
            if (!multiple) {
                info.fileList = [info.fileList[info.fileList.length - 1]];
            }

            const newFileList = await Promise.all(info.fileList.filter(f => f).map(async (file) => {
                if (file?.originFileObj && !file.url && previewIcon) {
                    return await resizeImage(file.originFileObj, 1920, 1920);
                }

                return file;
            }));
            setFileList(newFileList)
            onChange?.(newFileList)
        }

        const handlePreview = async (file: UploadFile) => {
            let src: any = ''
            if (!file.url && !file.preview) {
                src = await getBase64(file.originFileObj as any);
            }

            setPreview(file.url || src);
        };

        const resizeImage = (file: RcFile, maxWidth: number, maxHeight: number): Promise<UploadFile> => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const img = new Image();
                    img.src = reader.result as string;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;
                        if (width > maxWidth || height > maxHeight) {
                            if (width > height) {
                                height = (height * maxWidth) / width;
                                width = maxWidth;
                            } else {
                                width = (width * maxHeight) / height;
                                height = maxHeight;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        ctx?.drawImage(img, 0, 0, width, height);
                        canvas.toBlob((blob) => {
                            if (blob) {
                                const resizedFile = new File([blob], file.name, {type: file.type});
                                const uploadFile = convertRcFileToUploadFile(resizedFile as any);
                                uploadFile.uid = file.uid;
                                resolve(uploadFile);
                            }
                        }, file.type);
                    };
                };
            });
        };
        const beforeUpload = () => false;

        const onSortEnd = (sorted: UploadFile[]) => {
            setFileList(sorted)
            onChange?.(sorted)
        }

        const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

        const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
        const handleDragStart = useCallback((event: DragStartEvent) => {
            setActiveId(event.active.id);
        }, []);
        const handleDragEnd = useCallback((event: DragEndEvent) => {
            const {active, over} = event;
            if (active.id !== over?.id) {
                const oldIndex = fileList.findIndex(i => i.uid === active.id);
                const newIndex = fileList.findIndex(i => i.uid === over!.id);
                const newFileList = arrayMove(fileList, oldIndex, newIndex)
                setFileList(newFileList)
                onChange?.(newFileList)
            }

            setActiveId(null);
        }, [fileList]);
        const handleDragCancel = useCallback(() => {
            setActiveId(null);
        }, []);

        const currentItem = fileList.find(i => i.uid === activeId)


        return (
            <div>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={fileList.map(f => ({...f, id: f.uid}))} strategy={rectSortingStrategy}>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onRemove={handleRemove}
                            onPreview={handlePreview}
                            multiple={multiple}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                            showUploadList={{showPreviewIcon: previewIcon}}
                            accept={accept?.join(',')}
                            itemRender={(originNode, file) => {
                                addRender(file.uid, originNode)
                                return <SortableItem style={{minWidth: 'auto'}}
                                                     renderItem={(props, handleProps) =>
                                                         <div {...props}
                                                              className="position-relative h-100">
                                                             {originNode}
                                                             {multiple && (
                                                                 <Button icon={<DragOutlined/>}
                                                                         className={'position-absolute'}
                                                                         shape={"circle"}
                                                                         {...{
                                                                             ...handleProps,
                                                                             style: {
                                                                                 top: '-5px',
                                                                                 right: '-5px',
                                                                                 cursor: 'move',
                                                                                 zIndex: 1, ...handleProps.style
                                                                             }
                                                                         }}/>
                                                             )}
                                                         </div>
                                                     } key={file.uid} id={file.uid}/>
                            }}
                        >
                            <div>
                                <PlusOutlined/>
                                <div className="ant-upload-text">Nahrát</div>
                            </div>
                        </Upload>
                    </SortableContext>

                </DndContext>
                {
                    preview && <div className={'display-none'}>
                        <AntdImage src={preview} alt="preview" preview={{
                            visible: true,
                            onVisibleChange: () => setPreview(undefined),
                        }}/>

                    </div>
                }
            </div>
        )
            ;
    }
;

export default FileUploader;
