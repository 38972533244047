import React, {useEffect, useState} from 'react';
import './App.sass';
import './base.sass'
import Loading from "./components/Loading";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Base from "./layout/Base";
import Login from "./page/Login";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "./redux/store";
import {IAppState} from "./interface/redux/IAppState";
import {saveSetup} from "./redux/slice/Setup";
import SetupService from "./service/app/SetupService";
import ScrollToHash from "./util/ScrollToHash";
import ScriptManager from "./util/ScriptManager";
import {FloatButton, Popover, Tooltip} from 'antd';
import {InfoOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";

const scripts = [
    {src: '/js/jquery.js'},
    {src: '/js/jquery.magnific-popup.min.js'}, // Load Magnific Popup immediately after jQuery
    {src: '/js/swiper.min.js'},
    {src: '/js/isotope.pkgd.min.js'},
    {src: '/js/hammer.min.js'},
    {src: '/js/foxlazy.min.js'},
    {src: '/js/all.js'},
    {src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDqH-YPOECxBBvFpBoeW3SMsGq2i4lG-kY&language=en'},
    {src: '/js/google_map.js'}];

function App() {

    const [loading, setLoading] = useState(true);
    const loadingSetup = useSelector((state: IAppState) => state.setup.loading);
    const user = useSelector((state: IAppState) => state.setup.user);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {

        const timer = setTimeout(() => {
            load().then(() => setLoading(false))
        }, 1000);
        return () => {
            clearTimeout(timer);
        }
    }, []);

    const load = async () => {
        let setup = await SetupService.load();
        dispatch(saveSetup(setup));
    }

    return (
        <div className="App">
            <BrowserRouter>
                <Loading active={loading}/>
                <ScriptManager scripts={scripts}/>
                <ScrollToHash>
                    {loadingSetup ? <></> : <Routes>
                        <Route path="/*" element={<Base/>}/>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>}
                </ScrollToHash>
                {user && (
                    <FloatButton.Group
                        trigger="click"
                        type="primary"
                        style={{ insetInlineEnd: 24 }}
                        icon={<UserOutlined />}
                    >
                        <Popover content={user?.email} title={false} placement={'left'}>
                            <FloatButton icon={<InfoOutlined />} />
                        </Popover>
                        <Tooltip title={'Odhlásit se'} placement={'left'}>
                            <FloatButton icon={<LogoutOutlined />} onClick={() => {
                                localStorage.clear()
                                window.location.reload()
                            }}/>
                        </Tooltip>

                    </FloatButton.Group>
                )}
            </BrowserRouter>
        </div>
    );
}

export default App;
