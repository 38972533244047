import React, {MutableRefObject, useEffect, useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps {
    value?: string;
    onChange?: (value: string) => void;
}

const toolbarOptions = [[{'font': []}],
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    ['bold', 'italic', 'underline', 'strike'],
    [{'color': []}, {'background': []}],
    [{'script': 'sub'}, {'script': 'super'}],
    ['blockquote', 'code-block'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    [{'direction': 'rtl'}],
    [{'align': []}],
    ['link', 'image', 'video'],
    ['clean']]

const Wysiwyg: React.FC<QuillEditorProps> = ({value = '', onChange}) => {
        const handleChange = (content: string) => {
            if (onChange) {
                onChange(content);
            }
        };

        const editorRef = useRef<ReactQuill>(null);
        const initialContent = value;
        useEffect(() => {
            if (editorRef.current) {
                const quillEditor = editorRef.current.getEditor();
                quillEditor.clipboard.dangerouslyPasteHTML(initialContent);
            }
        }, []);

        return (<ReactQuill ref={editorRef} onChange={handleChange} theme="snow"
                            modules={{toolbar: toolbarOptions}}/>);
    }
;

export default Wysiwyg