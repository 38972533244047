import Fetcher, {API_URL, IFetcherService, IFetcherSubService} from "../Fetcher";
import {IFileObject} from "../../interface/data/IFileObject";
import {UploadFile} from "antd";
import {IFile} from "../../interface/data/IFile";

const NAME = 'files';

interface IFileObjectService extends IFetcherSubService<IFileObject>{
    buildSrcUrl: (id: string) => string,
    upload: (data: FormData) => Promise<IFileObject[]>
    uploadAndSync: (files: (UploadFile & IFile)[], originalFiles: IFile[]) => Promise<IFile[]>
}

const FileService : IFileObjectService = {
    create: (item) => {
        return Fetcher.create(item, NAME)
    },

    update:  (id, item) => {
        return Fetcher.update(id, item, NAME)
    },

    list: () => {
        return Fetcher.list(NAME)
    },

    get: (id) => {
        return Fetcher.get(id, NAME)
    },

    delete: (id) => {
        return Fetcher.delete(id, NAME)
    },
    buildSrcUrl: (id: string) => {
        return API_URL + "/files/" + id + "/preview"
    },
    upload(data: FormData): Promise<IFileObject[]> {
        return Fetcher.upload(data, NAME);
    },
    uploadAndSync: async (files: (UploadFile & IFile)[], originalFiles: IFile[] = []): Promise<IFile[]> => {
        const formData = new FormData();
        let newExist = false
        files.forEach(image => {
            if (!originalFiles.find(i => i.uid === image.uid)) {
                newExist = true
                formData.append('files[]', image.originFileObj as any, image.name);
            }
        })
        if (newExist) {
            const newFiles = await FileService.upload(formData)
            newFiles.forEach(newFile => {
                const index = files.findIndex(i => i.name === newFile.label);
                if (index > -1) {
                    files[index] = {uid: newFile.id, status: 'done', type: newFile.type, name: newFile.label}
                }
            })
        }
        return files
    }
};

export default FileService;
