import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Col, Row} from "antd";
import {Link} from "react-router-dom";
import {EyeInvisibleOutlined} from "@ant-design/icons";
import {IModularHouse} from "../../interface/data/IModularHouse";
import {AppDispatch} from "../../redux/store";
import {IAppState} from "../../interface/redux/IAppState";
import ModularHouseService from "../../service/app/ModularHouseService";
import {updateSetup} from "../../redux/slice/Setup";
import FileService from "../../service/app/FileService";
import HouseCardActions from "./HouseCardActions";


interface IProps {
    house: IModularHouse
    onEdit: (house: IModularHouse) => void
    handleProps?: any
}

const ModularHouseCard: React.FC<IProps> = ({house, onEdit, handleProps}) => {

    const houses = useSelector((state: IAppState) => state.setup.modularHouses);
    const user = useSelector((state: IAppState) => state.setup.user);
    const dispatch = useDispatch<AppDispatch>();

    const [deleting, setDeleting] = useState<undefined | string>();

    const remove = (house: IModularHouse) => {
        setDeleting(house.id)
        ModularHouseService.delete(house.id).then(() => {
            setDeleting(undefined)
            dispatch(updateSetup({modularHouses: houses.filter(h => h.id !== house.id)}));
        })
    }

    return (
        <div className="project-grid-wrapper"><Link
            className="project-grid-item-img-link"
            to={"/modulovy-domy/" + encodeURI(house.name)}>
            <div className="project-grid-item-img" style={{backgroundImage: `url(${FileService.buildSrcUrl(house.images[0]?.uid)})`}}>
                <img
                    src={FileService.buildSrcUrl(house.images[0]?.uid)}
                    className="s-img-switch wp-post-image"
                    alt="Lyon s terasou- nyní akce: kuchyňská linka a doprava zdarma!!!"/>
                {house.isPublic === false && user && (
                    <div className="overlay z-index-1">
                        <Alert type={'info'} banner={true} icon={<EyeInvisibleOutlined/>} description={'Neveřejný'}/>
                    </div>
                )}
            </div>
        </Link>
            <div className="project-grid-item-content">
                <HouseCardActions user={user} onClick={() => onEdit(house)} handleProps={handleProps}
                                  onConfirm={() => remove(house)} deleting={deleting} id={house.id}/>
                <Row justify={"space-between"} align={'middle'}>
                <div></div>
                    <Col><h4
                        className="project-grid-item-title text-capitalize"><Link
                        className="text-uppercase"
                        to={"/modulovy-domy/" + encodeURI(house.name)}>{house.name}</Link>
                    </h4>
                        <div
                            className="project-grid-item-category">{house.price?.toLocaleString()} Kč
                        </div>
                    </Col>
                    <Col className="flex flex-column"></Col>
                </Row>
            </div>
        </div>
    );
}

export default ModularHouseCard;
