import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

interface IProps {
    active: boolean
}

const Loading: React.FC<IProps> = ({active}) => {

    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        const timer = setTimeout(() => {
            setLoading(false)
        }, 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [location]);

    return (
        <div className="loading">
            <div className={"prague-loader " + (active || loading ? '' : 'is-loaded') } style={{width: "100vw", zIndex: 1000}}>
                <div className="prague-loader-wrapper" style={{width: "100vw"}}>
                    <div className="prague-loader-bar">Viva House</div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
