import React, {useState} from 'react';
import {Button, Col, Row} from "antd";
import {IAppState} from "../interface/redux/IAppState";
import {useDispatch, useSelector} from "react-redux";
import {Icons} from "../util/Icons";
import {Link} from "react-router-dom";
import RecordsOrderService from "../service/app/RecordsOrderService";
import Sorter from "../util/Sorter";
import ModularHouseCard from "./card/ModularHouseCard";
import {AppDispatch} from "../redux/store";
import {IModularHouse} from "../interface/data/IModularHouse";
import {updateSetup} from "../redux/slice/Setup";
import EditableText from "../components/EditableText";
import {EditOutlined} from "@ant-design/icons";
import ModularHouseCoverForm from "./form/ModularHouseCoverForm";
import EditableImage from "../components/EditableImage";

function Home() {

    const ORDER = 'home'
    const user = useSelector((state: IAppState) => state.setup.user);
    const all = useSelector((state: IAppState) => state.setup.modularHouses).filter(h => h.isPublic !== false);

    const houses = useSelector((state: IAppState) => state.setup.modularHouses).filter(h => h.onHomepage && ((h.isPublic !== false) || user));
    const orderList = useSelector((state: IAppState) => state.setup.recordsOrder);
    const sortedHouses = RecordsOrderService.sort(ORDER, orderList, houses);

    const homepageHouse = all.find(h => h.onHomepageCover === Math.max(...all.map(h => h.onHomepageCover || 0))) || houses[0];

    const dispatch = useDispatch<AppDispatch>();

    const [edit, setEdit] = useState<boolean | IModularHouse>(false);

    const [editCover, setEditCover] = useState<boolean>(false);

    const onSortEnd = (sorted: IModularHouse[]) => {
        dispatch(updateSetup({recordsOrder: RecordsOrderService.sortUpdate(ORDER, orderList, sorted)}));
        RecordsOrderService.create({id: ORDER, order: sorted.map(h => h.id)}).then(r => {
        })
    }

    return (
        <div>
            <div>
                <div className="container-fluid no-padd">
                    <div>
                        <div>
                            <div className="position-relative ">
                                <EditableImage buttonPosition={{includeHeader: true}} code={'homepage.cover.image'}
                                               defaultUrl={'https://www.vivahouse.eu/api/files/11134f60-bad9-4a5b-8cde-47ee3b5c1375/preview'}
                                               render={(url) => (
                                                   <div
                                                       className="container-fluid top-banner no-padd big fullheight light"
                                                       style={{
                                                           backgroundImage: 'url("' + url + '")',
                                                           backgroundPosition: 'center center',
                                                           backgroundSize: 'cover',
                                                           backgroundRepeat: 'no-repeat'
                                                       }}>
                                                       <span className="overlay"></span>
                                                       <Row align={'middle'} justify={"center"}
                                                            className="content flex-col">
                                                           <div className="prague-svg-animation-text"></div>
                                                           <div className="subtitle w-max-content position-relative">
                                                               <EditableText code={'home.cover.small.title'}>

                                                               </EditableText>
                                                           </div>
                                                           <h1 className="title display-linebreak position-relative w-max-content">
                                                               <EditableText code={'home.cover.big.title'}>
                                                                   Viva House přináší
                                                                   modulové a mobilní domy
                                                               </EditableText></h1>
                                                           <div className="position-relative"
                                                                style={{marginTop: '40px'}}>
                                                               {user && (
                                                                   <div className="position-absolute"
                                                                        style={{
                                                                            top: '-25px',
                                                                            right: '-32px',
                                                                            zIndex: 10
                                                                        }}>
                                                                       <Button icon={<EditOutlined/>} shape={'circle'}
                                                                               onClick={() => setEditCover(true)}/>
                                                                       {editCover &&
                                                                           <ModularHouseCoverForm
                                                                               onFinish={() => setEditCover(false)}
                                                                               house={homepageHouse}/>}
                                                                   </div>

                                                               )}

                                                               <Link
                                                                   className="a-btn creative mt-0"
                                                                   to={"/modulovy-domy/" + homepageHouse?.name}
                                                                   target="_self"><span className="a-btn-line"></span>ZOBRAZIT
                                                                   PROJEKT</Link>
                                                           </div>
                                                       </Row>
                                                       <div className="top-banner-cursor"></div>
                                                   </div>
                                               )}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container no-padd">
                    <div className="row-fluid margin-lg-70t">
                        <div
                            className="column col-sm-10 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-1 col-xs-12 margin-lg-65t margin-sm-0t no-padd">
                            <div className="no-padd-inner">
                                <div className="prague-counter multi_item no-figure">
                                    <div className="figures"></div>
                                    <div className="team-wrapper no-figure pt-0">
                                        <div className="trans_figures enable_animation"></div>
                                        <div className="team-outer" style={{height: '465px'}}>
                                            <div className="position-relative h-100">
                                                <EditableImage code={'homepage.brings'}
                                                               defaultUrl={'/img/zdravydom-domov-onas.jpg'}
                                                               render={(url) => (
                                                                   <img
                                                                       src={url}
                                                                       className="prague-team-img h-100 o-cover"
                                                                       alt=""/>
                                                               )}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="column col-sm-12 col-lg-offset-1 col-lg-5 col-md-offset-0 col-md-6 col-xs-12 margin-sm-20t padd-only-xs">
                            <div className="padd-only">
                                <div className="heading left dark"><h2
                                    className="title display-linebreak position-relative w-max-content"><EditableText
                                    type={"text"} code={'home.intro.title'}>
                                    Viva House
                                    přináší
                                    na trh modulové domy
                                </EditableText></h2>
                                    <div className="content padding-xs-40b">
                                        <EditableText type={"wysiwyg"} wrap={true} code={'home.intro.description'}>
                                            <p>Naším cílem je nabídnout dostupné bydlení, které své majitele
                                                nezatíží vysokými vstupními a provozními náklady. Je pro vás zděný dům
                                                příliš drahý? Modulové domy představují výhodnou alternativu, která
                                                naplní potřeby každé rodiny.</p>
                                        </EditableText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-fluid row-no-padding">
                    <div className="no-padd col-sm-12">
                        <div className="column-inner">
                            <div className="prague-shortcode-parent">
                                <div className="prague-shortcode-parent-img overflow-hidden"><span
                                    className="overlay z-index-1"></span>
                                    <div className="position-relative h-100">
                                        <EditableImage code={'homepage.why'}
                                                       defaultUrl={'/img/wood.jpg'}
                                                       render={(url) => (
                                                           <img src={url}
                                                                alt="middle-banner image"
                                                                className="o-cover w-100 h-100"/>
                                                       )}/>
                                    </div>
                                </div>
                                <div className="prague-shortcode-content-wrapp">
                                    <div className="prague-shortcode-heading light left"><h2
                                        className="parent-title">Proč právě Viva House?</h2></div>
                                    <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                                         data-start-page="1" data-max-page="2" data-next-link="index.php">
                                        <Row data-columns="prague_count_col3" data-gap="prague_gap_col15"
                                             className=" prague_services prague_count_col4 prague_gap_col15 no-footer-content prague-load-wrapper">
                                            <div className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <Col
                                                        className="prague-services-wrapper flex-col justify-items-center">
                                                        {Icons.review}
                                                        <h3 className="services-item-title position-relative"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '24px'
                                                            }}><EditableText type={"plain"}
                                                                             code={'home.why.customer.first'}>
                                                            Spokojenost
                                                        </EditableText></h3>
                                                        <div className="services-item-description position-relative"><h3
                                                            style={{fontSize: '24px'}}><EditableText type={"plain"}
                                                                                                     code={'home.why.customer.second'}>
                                                            Zákazníka
                                                        </EditableText></h3></div>
                                                        <Link className="prague-services-link a-btn-2 creative"
                                                              to="/proc-viva-house#spokojenost"><span
                                                            className="a-btn-line"></span>VÍCE</Link></Col>
                                                </div>
                                            </div>
                                            <div className="portfolio-item-wrapp prague_filter_class">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper">
                                                        {Icons.design}
                                                        <h3 className="services-item-title position-relative"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '24px'
                                                            }}><EditableText type={"plain"}
                                                                             code={'home.why.design.first'}>
                                                            Jedinečný
                                                        </EditableText></h3>
                                                        <div className="services-item-description position-relative"><h3
                                                            style={{fontSize: '24px'}}><EditableText type={"plain"}
                                                                                                     code={'home.why.design.second'}>
                                                            Dizajn
                                                        </EditableText></h3></div>
                                                        <Link className="prague-services-link a-btn-2 creative"
                                                              to="/proc-viva-house#dizajn"><span
                                                            className="a-btn-line"></span>VÍCE</Link></div>
                                                </div>
                                            </div>
                                            <div
                                                className="portfolio-item-wrapp prague_filter_class p_f_f9e81a7 column_paralax">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper">
                                                        {Icons.savings}
                                                        <h3 className="services-item-title position-relative"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '24px'
                                                            }}><EditableText type={"plain"}
                                                                             code={'home.why.saving.first'}>
                                                            Šetří
                                                        </EditableText></h3>
                                                        <div className="services-item-description position-relative"><h3
                                                            style={{fontSize: '24px'}}><EditableText type={"plain"}
                                                                                                     code={'home.why.saving.second'}>
                                                            Provozní náklady
                                                        </EditableText></h3></div>
                                                        <Link className="prague-services-link a-btn-2 creative"
                                                              to="/proc-viva-house#setrenie" target="_self"><span
                                                            className="a-btn-line"></span>VÍCE</Link></div>
                                                </div>
                                            </div>
                                            <div className="portfolio-item-wrapp prague_filter_class p_f_f9e81a7">
                                                <div className="portfolio-item">
                                                    <div className="prague-services-wrapper">
                                                        {Icons.quality}
                                                        <h3 className="services-item-title position-relative"
                                                            style={{
                                                                fontWeight: 'normal',
                                                                fontSize: '24px'
                                                            }}><EditableText type={"plain"}
                                                                             code={'home.why.quality.first'}>
                                                            Vysoká
                                                            kvalita
                                                        </EditableText></h3>
                                                        <div className="services-item-description position-relative"><h3
                                                            style={{fontSize: '24px'}}><EditableText type={"plain"}
                                                                                                     code={'home.why.quality.second'}>
                                                            A rychlost stavby
                                                        </EditableText></h3></div>
                                                        <Link className="prague-services-link a-btn-2 creative"
                                                              to="/proc-viva-house#kvalita" target="_self"><span
                                                            className="a-btn-line"></span>VÍCE</Link></div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container no-padd">
                    <div className="row-fluid margin-lg-55t margin-sm-20t">
                        <div className="col-sm-12 no-padd margin-lg-20b margin-xs-40t">
                            <div>
                                <div className="heading left dark"><h2
                                    className="d-flex justify-content-center title">Modulové domy</h2>
                                    <div className="d-flex justify-content-center text-center m-auto"
                                         style={{width: '80%'}}>
                                        <EditableText type={"wysiwyg"} wrap={true}
                                                      code={'home.modular-houses.description'}>
                                            <p>Modulové dřevostavby jsou moderním, cenově dostupným řešením
                                                bydlení. Všechny domy jsou vyhotoveny z kvalitních materiálů v
                                                nízkoenergetickém standardu. Poskytují zdravé prostředí a dokonalý
                                                komfort, který uspokojí i náročného klienta.</p>
                                        </EditableText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container no-padd">
                    <div className="row-fluid margin-lg-5t margin-lg-140b margin-sm-70b no-padd">
                        <div
                            className="column no-padd column_container col-sm-12 margin-lg-5t margin-lg-140b margin-sm-70b">
                            <div className="column-inner">
                                <div className="wrapper">
                                    <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more"
                                         data-start-page="1" data-max-page="4">
                                        <Row justify={"space-around"} className="w-100 m-0" gutter={[32, 32]}>
                                            <Sorter onSortEnd={onSortEnd} items={sortedHouses}
                                                    renderItem={(house, props, handleProps) => <Col xs={24} sm={12}
                                                                                                    lg={8}
                                                                                                    {...props}><ModularHouseCard
                                                        house={house} onEdit={setEdit}
                                                        handleProps={handleProps}/></Col>}/>
                                        </Row>
                                        <div className="row m-0"><br/>
                                            <div className="col-sm-12 text-center"><Link to="/modulovy-domy">
                                                <button className="a-btn-2 creative viva-button"><span
                                                    className="a-btn-line load"></span>Zobrazit všechny
                                                </button>
                                            </Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
