// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import Setup from "./slice/Setup";

export const store = configureStore({
    reducer: {
        setup: Setup,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

