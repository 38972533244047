import React, {FC, JSX, ReactNode, useCallback, useState} from 'react';
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import SortableItem from './sorter/SortableItem';
import Item from './sorter//Item';

interface IProps {
    items: any[],
    onSortEnd: (items: any[]) => void,
    renderItem: (item: any, props: any, handleProps: any) => JSX.Element;
    chidlren?: ReactNode;
}

const Sorter: FC<IProps> = ({onSortEnd, renderItem, items}) => {
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id);
    }, []);
    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = items.findIndex(i => i.id === active.id);
            const newIndex = items.findIndex(i => i.id === over!.id);
            onSortEnd(arrayMove(items, oldIndex, newIndex))
        }

        setActiveId(null);
    }, [items]);
    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const currentItem = items.find(i => i.id === activeId)

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items.map((item) => (
                        <SortableItem renderItem={(props, handleProps) => renderItem(item, props, handleProps)} key={item.id} id={item.id} />
                    ))}
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                {activeId ? <Item className={'p-0 is-dragging'} renderItem={(props, handleProps) => renderItem(currentItem, props, handleProps)} id={activeId.toString()} isDragging /> : null}
            </DragOverlay>
        </DndContext>
    );
};

export default Sorter;