import React, {useState} from 'react';
import {Alert, Button, Card, Form, Image, Input, notification, Row} from "antd";
import Fetcher from "../service/Fetcher";
import {useNavigate} from "react-router-dom";
import {LoginOutlined} from "@ant-design/icons";
import SetupService from "../service/app/SetupService";
import {saveSetup} from "../redux/slice/Setup";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../redux/store";

function Login() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const login = (values: {email: string, password: string}) => {
        setLoading(true)
        Fetcher.create(values, 'token').then(async response => {
            const key = response['_AKey']
            setLoading(false)
            if (key){
                localStorage.setItem('_AKey', response['_AKey'])
                let setup = await SetupService.load();
                dispatch(saveSetup(setup));
                notification['success']({
                    message: 'Úspěšně přihlášeni',
                });
                navigate('/');
            } else {
                setError(true)
            }
        })
    }

    return (
        <Row justify={"center"} className="w-100"
             style={{backgroundImage: "url('/img/wood.jpg')", height: "100vh", backgroundSize: "cover"}}>
            <div>
                <Card bordered={true} cover={<Image src="/img/logo.png"/>}
                      style={{backgroundColor: 'black', marginTop: '65px', border: '0px', padding: '20px'}}>
                    <Form onFinish={login}>
                        <Form.Item name={'email'} label={<div className={'font-white'}>Email</div>} rules={[{required: true, message: 'Please enter a valid email address'}]}>
                            <Input type={'email'}/>
                        </Form.Item>
                        <Form.Item name={'password'} label={<div className={'font-white'}>Heslo</div>} rules={[{required: true, message: 'Please enter a valid email address'}]}>
                            <Input.Password/>
                        </Form.Item>
                        {error && <Alert type={'error'} description={'Neplatné přihlašovací údaje'}/>}
                        <Row justify={"end"}>
                            <Button htmlType={"submit"} icon={<LoginOutlined/>} loading={loading}>Login</Button>
                        </Row>
                    </Form>
                </Card>
            </div>
        </Row>
    );
}

export default Login;
